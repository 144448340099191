@charset "UTF-8";
@import "../common/css/_variables";
@import "../common/css/_mixin";

// pc
@import "_modules_pc";
@import "_inq_pc";
@import "_sdgs_pc";
@import "_diversity_pc";

@include max-screen {
  // sp
  @import "_modules_sp";
  @import "_inq_sp";
	@import "_sdgs_sp";
	@import "_diversity_sp";

}