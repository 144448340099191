// =========================================
//   main
// =========================================
#main {
  padding-top: 50px;
}


// =========================================
//   modules
// =========================================
h1 {
  margin: 0 0 35px;
  padding: 0;
  font-size: 40px;
  line-height: 1.4;
  text-align: center;
  &.line {
    margin: 0 0 20px;
    padding: 0 0 30px;
    border-bottom: solid 1px #E6E6E6;
  }
  &.b-wide {
    margin-bottom: 30px;
  }
  span {
    display: block;
    margin: 0 0 8px;
    font-size: 24px;
    &.let-space {
      letter-spacing: 4px;
    }
    &.h-box {
      display: block;
      width: 370px;
      margin: 0 auto;
      padding: 10px 0;
      background-color: #447f4b;
      color: #fff;
      font-size: 34px;
      font-weight: normal;
      letter-spacing: -1px;
    }
  }
  &.page404 {
    margin-bottom: 52px;
    padding-top: 45px;
    font-size: 78px;
    line-height: 1.3;
    letter-spacing: 5px;
    span {
      font-size: 22px;
      font-weight: normal;
      letter-spacing: 2px;
    }
  }
}
.only-txt{
  margin-bottom: 47px;
}
.h1_mb_large{
  margin-bottom: 58px;
}

h2 {
  margin: 0 0 25px;
  padding: 0 0 0 0;
  font-size: 26px;
  line-height: 1.7;
  text-align: left;
  &.line {
    margin: 0 0 25px;
    padding: 0 0 15px 15px;
    border-bottom: solid 1px #ebebeb;
  }
  &.h-center {
    text-align: center;
  }
  &.h-large {
  font-size: 32px;
  }
  &.b-short {
    margin-bottom: 10px;
  }
  &.b-tall {
    margin-bottom: 50px;
  }
  &.b-top {
    margin: 70px 0 0;
  }
  span {
    padding: 0 15px;
    font-size: 16px;
    font-weight: normal;
    vertical-align: 3px;
  }
}
.h2subtxt{
  font-size: 12px;
}

h3 {
  margin: 0 0 15px;
  padding: 0 0 0 15px;
  font-size: 20px;
  line-height: 1.4;
  text-align: left;
  &.h-center {
    text-align: center;
  }
  &.h-large {
    font-size: 30px;
  }
  span {
    padding-left: 15px;
    font-size: 13px;
    font-weight: normal;
    &.red {
      padding-left: 0;
      font-size: 20px;
      color: $color_red;
      font-weight: bold;
    }
  }
}

h4 {
  margin-bottom: 15px;
}

.main-img {
  margin: 0 0 45px;
  line-height: 0;
  text-align: center;
  &.b-short {
    margin-bottom: 25px;
  }
  &.none {
    margin-bottom: 0;
  }
  img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;

    &.w400 {
      width: 400px;
    }
  }
}

main p {
  margin: 0 0 30px;
  font-size: 14px;
  line-height: 1.78;
  &.leading {
    margin-bottom: 40px;
  }
  &.b-short {
    margin-bottom: 20px;
  }
  &.b-short02 {
    margin-bottom: 10px;
  }
  &.b-none {
    margin-bottom: 0;
  }
  &.space-l {
    margin-left: 265px;
  }
  &.space-l2 {
    margin-left: 55px;
  }
  &.txt-left {
    text-align: left;
  }
  &.txt-right {
    text-align: right;
  }
  &.none {
    margin-bottom: 0;
  }
}

a {
  &:hover {
    img {
      opacity:0.7;
      filter:alpha(opacity=70);
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -o-transition: 0.4s;
      -ms-transition: 0.4s;
      transition: 0.4s;
    }
  }
}
.link_bullet {
  @include arrow-left(4px);
}

.space-lr {
  padding: 0 15px;
}

.space-lr02 {
  padding: 0 45px;
}

.space-lr03 {
  padding-left: 30px;
  padding-right: 30px;
}

.space-lr04 {
  padding-left: 60px;
  padding-right: 60px;
}

.space-lr05 {
  padding-left: 45px;
  padding-right: 45px;
}

.js-tab-contents {
  display: none;
}

.w-short {
  width: 934px;
  margin: 0 auto;
}

.txt-center {
  text-align: center;
}

.txt-large {
  font-size: 16px;
}
.txt-small {
  font-size: 12px;
}

.list-two-col {
  margin: 0 0 40px;
  li {
    display: block;
    float: left;
    width: 500px;
    margin: 0 0 20px;
    border: solid 1px #e0e0e0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    &:nth-child(odd) {
      clear: both;
    }
    &:nth-child(2n) {
      float: right;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
    a {
      position: relative;
      display: block;
      padding: 20px 40px 20px 54px;
      color: #222;
      font-size: 20px;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        color: #e60012;
      }
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 29px;
        margin-top: -8px;
        box-sizing: border-box;
        width: 11px;
        height: 16px;
        border: 8px solid transparent;
        border-left: 11px solid #e60012;
      }
      &.li-a {
        padding:6px 40px 6px 54px;
      }
      span {
        vertical-align: 2px;
        padding-right: 15px;
        font-size: 15px;
        &.blo {
          vertical-align: 0;
          display: block;
          line-height: 1.0;
        }
      }
      &.on {
        color: #e60012;
      }
      &.close-win {
        background-color: #eee;
        &:before {
          display: none;
        }
      }
      &.nolink {
        background-color: #f5f5f5;
        opacity: 0.5;
        pointer-events: none;
        &:hover {
          color: #222;
        }
      }
    }
    .height-adjust{
      padding: 28px 40px 28px 54px;
    }
  }
}

.list-three-col {
  margin: 0 0 20px;
  &.space-t {
    margin-top: 55px;
  }
  li {
    display: block;
    float: left;
    width: 328px;
    margin: 0 20px 20px 0;
    border: solid 1px #e0e0e0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &.b-none {
      margin-bottom: 0;
    }
    a {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding: 15px 40px 15px 54px;
      color: #222;
      font-size: 14px;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        color: #e60012;
      }
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 29px;
        margin-top: -6px;
        box-sizing: border-box;
        width: 9px;
        height: 12px;
        border: 6px solid transparent;
        border-left: 9px solid #e60012;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        -ms-transition: 0.4s;
        transition: 0.4s;
      }
      &.on {
        color: #e60012;
      }
      &.l-space {
        letter-spacing: -1px;
      }
      &.arrow {
        &:before {
          position: absolute;
          content: "";
          top: 50%;
          left: 29px;
          width: 10px;
          height: 10px;
          margin-top: -9px;
          box-sizing: border-box;
          border: 0 solid transparent;
          border-left: none;
          border-bottom: 2px solid #e60012;
          border-right: 2px solid #e60012;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    &.on {
      a {
        &.arrow {
          &:before {
            margin-top: -4px;
            -webkit-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
    &.arrow-content {
      display: none;
      width: 100%;
      margin: 0 0 20px;
      padding: 30px 30px 0;
      background-color: #f5f5f5;
      a {
        float: left;
        width: 305px;
        margin: 0 23px 30px 0;
        padding: 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:before {
          display: none;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 0 4px;
        }
        span {
          display: block;
          position: relative;
          padding-left: 12px;
          &:before {
            position: absolute;
            content: "";
            top: 4px;
            left: 0;
            margin-top: 0;
            box-sizing: border-box;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 5px solid #e60012;
          }
        }
      }
    }
  }
  &.border-none {
    li {
      border: none;
    }
  }
}

.list-definition {
  padding-bottom: 5px;
  dt {
    font-size: 16px;
    margin-bottom: 5px;
  }
  dd {
    padding-bottom: 20px;
  }
}

.tab-three-col {
  margin: 0 0 20px;
  border-bottom: solid 1px #e0e0e0;
  li {
    display: block;
    float: left;
    width: 341px;
    margin: 0;
    a {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding: 15px 40px 15px 54px;
      background-color: #fff;
      color: #222;
      font-size: 14px;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        background-color: #f5f5f5;
      }
      &.on {
        background-color: #f5f5f5;
      }
    }
    &.on a {
      background-color: #f5f5f5;
    }
  }
}

.prof {
  margin: 0 0 20px;
  padding: 0 0 40px;
  border-bottom: solid 1px #e6e6e6;
  .img-area {
    float: left;
    width: 210px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .txt-area {
    float: left;
    width: 200px;
    padding: 105px 0 0 30px;
    text-align: right;
    .position {
      display: inline-block;
      padding-bottom: 5px;
      font-size: 14px;
    }
    img {
      width: 97px;
      height: 22px;
    }

  }
}

.global-two-col {
  margin: 0 0 10px;
  padding: 0 0;
  li {
    display: block;
    float: left;
    width: 480px;
    margin: 0 0 70px 0;
    &:nth-child(2n) {
      float: right;
    }
    &:nth-child(2n+1) {
      clear: both;
    }
    h3 {
      margin: 0 0 15px;
      padding: 0 0 10px 0;
      border-bottom: dashed 1px #bfbfbf;
      font-size: 20px;
      line-height: 1.2;
      text-align: left;
      .num {
        font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", "sans-serif", "ＭＳ Ｐゴシック", "MS PGothic";
        display: block;
        float: left;
        width: 24px;
        height: 24px;
        margin: 0 0 0 0;
        padding: 3px 0 0 0;
        background-color: #e60012;
        color: #fff;
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        font-weight: bold;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        &.space-r {
          margin-right: 5px;
        }
      }
    }
    .img-area {
      width: 100%;
      margin: 0 0 8px;
      img {
        width: 100%;
        height: auto;
      }
      .img-none {
        height: 280px;
      }
    }
    .img-width {
      img {
        width: auto;
      }
    }
    .info-area {
      float: left;
      width: 100%;
      line-height: 1.6;
      dt {
        clear: both;
        float: left;
        width: 27%;
        span {
          letter-spacing: 2em;
        }
        &.shikichi,
        &.kibo,
        &.youto {
          span {
            letter-spacing: 1em;
          }
        }
        &.nobeyuka {
          span {
            letter-spacing: 0.2em;
          }
        }
        &.shunkou {
          span {
            letter-spacing: 5em;
          }
        }
      }
      dd {
        float: left;
        width: 73%;
      }
    }
    .txt-area {
      clear: both;
      ul {
        margin: 0 0 0 0;
        padding: 0 0 0 20px;
        text-align: left;
        li {
          display: list-item;
          float: none;
          width: auto;
          margin: 0 0 0 0;
          list-style-type: disc;
          list-style-position: outside;
          &:nth-child(2n) {
            float: none;
          }
        }
      }
    }
  }
}

.img-center {
  margin: 0 0 50px;
  line-height: 0;
  text-align: center;
  &.b-none {
    margin-bottom: 0;
  }
  &.b-short {
    margin-bottom: 30px;
  }
  &.global_map {
    img {
      width: 100%;
    }
  }
  &.img-red {
    margin-top: -90px;
    margin-bottom: -30px;
    margin-left: -150px;
    img {
      width: 360px;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
  }
}

#main .icon {
  &-doc {
    display: inline-block;
    padding-left: 20px;
    position: relative;
    &::before {
      background: url(/common/img/icon_doc.png) 0 0 no-repeat;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      margin-top: 6px;
      padding: 0;
      border: none;
    }
  }
  &-doc-blue {
    display: inline-block;
    padding-left: 20px;
    position: relative;
    &::before {
      background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
      background-size: 15px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      margin-top: 6px;
      padding: 0;
      border: none;
    }
  }
  &-pdf {
    display: inline-block;
    padding-left: 25px;
    position: relative;
    &::before {
      background: url(/common/img/icon_pdf.png) 0 0 no-repeat;
      background-size: 20px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      padding: 0;
      border: none;
    }
  }
  &-serch {
    display: inline-block;
    padding-left: 24px;
    position: relative;
    &::before {
      background: url(/common/img/icon_serch.png) 0 0 no-repeat;
      background-size: 14px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      margin-top: 4px;
      padding: 0;
      border: none;
    }
  }
  &-new {
    &::after {
      color: $color_red;
      content: "NEW";
      font-size: 12px;
      display: inline-block;
      padding-left: 10px;
    }
  }
}

.img-link {
  margin: 0 0 50px;
  line-height: 0;
  position: relative;
  text-align: center;
  &.b-none {
    margin-bottom: 0;
  }
  img {
    display: block;
    margin: 0 auto;
    width: auto;
    height: auto;
  }
  .txt-area {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    .ttl {
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      &.black {
        color: $color_black;
      }
    }
    .tel {
      margin-bottom: 0;
      span {
        color: #fff;
        display: inline-block;
        font-size: 60px;
        font-weight: bold;
        line-height: 1;
        position: relative;
      }
    }
  }
  &.txt-shadow {
    .ttl,
    .tel {
      text-shadow: 0 0 20px #000;
    }
    .tel {
      margin-bottom: 10px;
    }
  }
}

.img-right-wrap {
  margin-bottom: 50px;
  &.b-wide {
    margin-bottom: 80px;
  }
  &.b-short {
    margin-bottom: 30px;
  }
  p {
    float: left;
    width: 605px;
    margin-right: 29px;
    padding: 0 0 0 15px;
  }
  .size-adjust{
    width: 714px;
  }
  img {
    display: inline;
    max-width: 360px;
    height: auto;
    margin: 0 15px 0 0;
  }
  &.upper-img {
    p {
      float: left;
      width: 605px;
      margin-right: 0;
      padding: 0 0 0 15px;
    }
    img {
      float: right;
      margin-right: 15px;
    }
  }
}

.img-left-wrap {
  margin-bottom: 50px;
  &.b-wide {
    margin-bottom: 80px;
  }
  img {
    display: block;
    float: left;
  }
  p {
    float: right;
    width: 640px;
    height: 259px;
    margin: 0;
    padding: 0 30px;
    background-color: #f5f5f5;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: -webkit-center;
    align-items: -moz-flexcenter;
    align-items: -ms-center;
    align-items: -o-center;
    align-items: center;
    justify-content: -webkit-center;
    justify-content: -moz-flexcenter;
    justify-content: -ms-center;
    justify-content: -o-center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .info-area {
    float: right;
    width: 640px;
    height: 278px;
    margin: 0;
    padding: 80px 30px 0;
    background-color: #f5f5f5;
    font-size: 13px;
    line-height: 1.8;
    dt {
      clear: both;
      float: left;
      width: 130px;
      span {
        letter-spacing: 2em;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        span {
          letter-spacing: 1em;
        }
      }
      &:nth-of-type(4) {
        span {
          letter-spacing: 0.5em;
        }
      }
      &:nth-of-type(5) {
        span {
          letter-spacing: 5em;
        }
      }
    }
    dd {
      float: left;
      width: 450px;
    }
  }
}

.break-line {
  width: 100px;
  height: 30px;
  margin: 0 auto 50px;
  border-bottom: solid 1px #D6D6D6;
  &.short {
    height: 30px;
    margin: 0 auto 20px;
  }
}

.list-detail {
  margin: 0 0 80px;
  li {
    display: block;
    float: left;
    width: 500px;
    margin: 0 0 20px;
    background-color: #f5f5f5;
    &:nth-child(odd) {
      clear: both;
    }
    &:nth-child(2n) {
      float: right;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
    a {
      display: block;
      text-decoration: none;
    }
    .img-area {
      float: left;
      width: 200px;
    }
    .txt-area {
      float: left;
      width: 300px;
      height: 170px;
      text-align: center;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: -webkit-center;
      align-items: -moz-flexcenter;
      align-items: -ms-center;
      align-items: -o-center;
      align-items: center;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      h3 {
        margin: 0 0 13px;
        padding: 0;
        color: #222;
        font-size: 20px;
        text-align: center;
        line-height: 1.2;
        &.check {
          color: #0069a0;
        }
        &.eco {
          color: #76a22d;
        }
        &.custom {
          color: #00498a;
        }
        &.life {
          color: #d0850c;
        }
        &.commu {
          color: #b94546;
        }
        span {
          display: block;
          margin: 0 0 0;
          padding: 0;
          color: #333;
          font-size: 14px;
          line-height: 1.7;
        }
      }
    }
  }
}

.t-flow {
  width: 100%;
  margin: 0 15px 40px;
  &.b-short {
    margin-bottom: 20px;
  }
  th {
    width: 170px;
    background-color: #F5F5F5;
    font-size: 16px;
    line-height: 1.4;
    vertical-align: middle;
    &.red {
      color: #E50013;
    }
  }
  td {
    padding: 15px 20px;
    vertical-align: middle;
    &.arrow {
      padding: 10px 20px 15px;
      div {
        position: relative;
        margin: 0 auto;
        width: 2px;
        height: 15px;
        background-color: #C61D21;
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: -5px;
          margin: auto;
          width: 10px;
          height: 0;
          border: 6px solid transparent;
          border-top: 10px solid #C61D21;
        }
      }
    }
  }
}

.t-flow.mod_redevelopment {
  border-collapse: separate;
  border-spacing: 24px;
  margin: 0 15px 40px;
  width: 100%;
  transform: translate(0, -24px);
  &.b-short {
    margin-bottom: 20px;
  }
  th {
    width: 357px;
    font-size: 20px;
    line-height: 1.4;
    text-align: left;
    vertical-align: middle;
    em {
      display: inline-block;
      padding: 0 35px;
      height: 100%;
      vertical-align: middle;
      font-family: sans-serif;
      font-size: 16px;
      font-style: normal;
      color: #E60012;
      border-right: solid 1px #E60012;
      small {
        margin-right: 5px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    span {
      display: inline-block;
      padding-left: 25px;
      vertical-align: middle;
    }
    &.red {
      color: #E50013;
    }
  }
  td {
    padding: 15px 20px;
    vertical-align: middle;
    border: solid 10px;
    transform: translate(-34px, 0px);
    &.arrow {
      padding: 10px 20px 15px;
      div {
        position: relative;
        margin: 0 auto;
        width: 2px;
        height: 15px;
        background-color: #C61D21;
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: -5px;
          margin: auto;
          width: 10px;
          height: 0;
          border: 6px solid transparent;
          border-top: 10px solid #C61D21;
        }
      }
    }//&.arrow
    ol {
      li {
        position: relative;
        margin-bottom: 1em;
        font-size: 18px;
        text-align: center;
        &::after {
          position: absolute;
          bottom: -31px;
          left: 50%;
          content: '';
          display: block;
          margin-left: -7px;
          width: 14px;
          height: 28px;
          border-top: solid 14px #E60012;
          border-right: solid 7px transparent;
          border-bottom: solid 14px transparent;
          border-left: solid 7px transparent;
        }
        &:last-child {
          margin-bottom: 0;
          &::after {
            display: none;
          }
        }
      }
    }
    .mod_col1 {
      margin: 0 auto;
      width: 65%;
      li {
        width: 100%;
        font-size: 18px;
      }
    }
    .mod_col2 {
      li {
        width: 49%;
        font-size: 14px;
      }
    }
  }// td
  tr {
    position: relative;
    &::after {
      position: absolute;
      bottom: -48px;
      left: 50%;
      content: '';
      display: block;
      margin-left: -13px;
      width: 26px;
      height: 48px;
      border-right: solid 13px transparent;
      border-bottom: solid 24px transparent;
      border-left: solid 13px transparent;
    }
    &:nth-of-type(1) {
      &::after {
        border-top: solid 24px #FFEFE6;
      }
      th {
      background: #FFEFE6;
      }
      td {
        border-color: #FFEFE6; 
      }
    }
    &:nth-of-type(2) {
      &::after {
        border-top: solid 24px #FFE5D6;
      }
      th {
        background: #FFE5D6;
      }
      td {
        border-color: #FFE5D6; 
      }
    }
    &:nth-of-type(3) {
      &::after {
        border-top: solid 24px #FFD8C2;
      }
      th {
        background: #FFD8C2;
      }
      td {
        border-color: #FFD8C2; 
      }
    }
    &:nth-of-type(4) {
      &::after {
        border-top: solid 24px #FFCAAD;
      }
      th {
        background: #FFCAAD;
      }
      td {
        border-color: #FFCAAD; 
      }
    }
    &:nth-of-type(5) {
      &::after {
        display: none;
      }
      th {
      background: #FFBE99;
      }
      td {
        border-color: #FFBE99; 
      }
    }
  }// tr
}//.t-flow.mod_redevelopment

.t-point {
  width: 100%;
  margin: 0 15px 40px;
  th {
    width: 390px;
    padding: 25px 20px;
    background-color: #FFEFE6;
    border-bottom: solid 25px #fff;
    font-size: 17px;
    line-height: 1.4;
    vertical-align: middle;
    text-align: left;
  }
  td {
    padding: 0 20px;
    border-bottom: solid 25px #fff;
    vertical-align: middle;
  }
}

.disc {
  padding-left: 20px;
  &.space-b {
    margin-bottom: 40px;
  }
  li {
    list-style-position: outside;
    list-style-type: disc;
  }
  &.space {
    li {
      margin-bottom: 20px;
    }
  }
}

.no-disc {
  li {
    list-style-type: none;
  }
  &.space-l {
    padding-left: 20px;
    li {
      padding-left: 20px;
      text-indent: -20px;
    }
  }
  &.space {
    li {
      margin-bottom: 20px;
    }
  }
}

.disc-float {
  padding-left: 20px;
  li {
    float:left;
    width: 220px;
    list-style-position: outside;
    list-style-type: disc;
    &:nth-child(2n) {
      width: 300px;
    }
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.accordion {
  margin: 0 0 50px;
  li {
    margin: 0 0 15px;
    padding: 15px 15px 0;
    background-color: #F5F5F5;
    border: silid 1px #EBEBEB;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    .question {
      position: relative;
      margin: 0 0 0;
      padding: 0 0 13px 30px;
      font-size: 16px;
      line-height: 1.4;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 19px;
        height: 19px;
        background-color: #fff;
        border: 1px solid #C81C1E;
        text-align: center;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
      }
      &:after {
        content: "+";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        top: -2px;
        left: 0;
        color: #C81C1E;
        text-align: center;
      }
      &.on {
        &:before {
          background-color: #C81C1E;
        }
        &:after {
          content: "−";
          top: -2px;
          left: 0;
          color: #fff;
        }
      }
    }
    .answer {
      display: none;
      padding: 15px 0 15px;
      border-top: dashed 1px #d0d0d0;
    }
    &:nth-child(1) {
      .answer {
        display: block;
      }
    }
  }
}

.img-two-col {
  margin: 0 0 10px;
  padding: 0 0;
  &.space-lr02 {
    padding: 0 45px;
  }
  &-autoheight {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    h3 {
      font-size: 15px;
      padding-left: 0;
    }
    li {
      width: 48%;
      padding-bottom: 30px;
    }
  }
  &.narrow {
    margin: 0 auto;
    width: 632px;
    li {
      margin-bottom: 5px;
    }
    .txt-area {
      margin-top: 10px;
    }
  }
  li {
    display: block;
    float: left;
    width: 48%;
    margin: 0 0 0 0;
    &:nth-child(2n) {
      float: right;
    }
    .img-area {
      img {
        width: 100%;
        height: auto;
      }
    }
    .txt-area {
      h3 {
        margin: 0;
        padding: 5px 0 15px;
        color: #222;
        font-size: 13px;
        text-align: center;
        line-height: 1.4;
      }
      p {
        margin: 0 0 10px;
        text-align: left;
      }
    }
  }
}

.img-three-col {
  margin: 0 0 80px;
  padding: 0 15px;
  li {
    display: block;
    float: left;
    width: 311px;
    margin: 0 30px 30px 0;
    &:nth-child(3n+1) {
      clear: both;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
    &:nth-last-child(3) {
      margin-bottom: 0;
    }
    .img-area {
      img {
        width: auto;
        height: auto;
      }
    }
    .txt-area {
      h3 {
        margin: 0;
        padding: 15px 0 8px;
        color: #222;
        font-size: 15px;
        text-align: center;
        line-height: 1.4;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .txt-area {
    text-align: center;
    p {
      display: inline-block;
      text-align: left;
    }
  }
}

.gray-box {
  margin: 0 auto 80px;
  padding: 28px 0 30px;
  background-color: #ebebeb;
  text-align: center;
  h3 {
    margin: 0 0 13px;
    padding: 0;
    font-size: 22px;
    line-height: 1.4;
    text-align: center;
  }
  &.space-tb {
    padding: 50px 0 50px;
    h3 {
      font-size: 24px;
    }
  }
  &.narrow {
    width: 740px;
  }
  &.mod_inverse {
    background: #000;
    h3 {
      font-size: 18px;
      color: #fff;
    }
    .btn-black {
      background: #FFF;
      color: #000;
    }
    a {
      color: #000;
      &:hover {
        .btn-black {
          background: #999;
        }
      }
    }
  }
}
#main .gray-box.mod_inverse .icon-doc::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 5px;
  margin-top: -6px;
  box-sizing: border-box;
  width: 9px;
  height: 12px;
  border: 6px solid transparent;
  border-left: 9px solid #e60012;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: none;
}

.gray-box02 {
  margin: 0 auto 80px;
  padding-top: 28px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  &.space-t {
    padding: 30px 0 0;
  }
  &.space-tb {
    padding: 45px 0 30px;
  }
  &.space-tb02 {
    padding-top: 33px;
    padding-bottom: 30px;
  }
  &.narrow {
    padding: 10px;
    width: 628px;
    .white-box {
      padding: 55px 10px 25px;
    }
    .img-area {
      margin-bottom: 35px;
    }
  }
  &.little-large {
    width: 934px;
    margin-bottom: 45px;
  }
  h3 {
    padding-left: 0;
  }
  .point-three-col {
    padding: 0 40px 25px;
  }
  .box-line {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
    padding-bottom: 25px;
    &-dotted {
      border-bottom: 1px dotted #e1e1e1;
      margin-bottom: 25px;
    }
  }
  .img-area {
    text-align: center;
    .img-caption {
      font-size: 12px;
      margin-top: 5px;
    }
  }
}

.gray-box03 {
  margin: 0 auto 80px;
  padding: 20px;
  background-color: #eee;
  &.narrow {
    width: 640px;
  }
  &.medium {
    padding: 30px;
    width: 710px;
    .img-caption {
      margin-bottom: -10px;
    }
  }
  &.space-tblr {
    padding: 50px 50px 40px;
  }
  .h-center {
    text-align: center;
  }
  .white-box {
    padding: 50px 50px 40px;
    li {
      border-bottom: 2px solid #e0e0e0;
      margin-bottom: 70px;
      padding-bottom: 40px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -33px;
        margin: 0 auto;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        border: 15px solid transparent;
        border-top: 18px solid #e0e0e0;
      }
      &:last-child {
        border-bottom: 0 none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::after {
          content: none;
        }
      }
    }
    dl {
      dt {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
    .txt-area-gray {
      background-color: #f5f5f5;
      font-size: 15px;
      margin: 0 auto 20px;
      padding: 10px;
      width: 320px;
    }
  }
  .img-area {
    margin-bottom: 20px;
    &-two-col {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      img {
        margin-right: 30px;
      }
    }
  }
  .txt-area {
    display: inline-block;
    text-align: left;
  }
  .img-caption {
    font-size: 18px;
    margin-top: 15px;
    text-align: center;
  }
  .inner_col2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .col_main {
      width: 520px;
    }
    .col_sub {
      width: 300px;
    }
    p {
      margin: 0 !important;
    }
  }
}

.white-box {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  .txt-area {
    margin: 20px 0;
    text-align: left;
  }
}
a.white-box {
  display: block;
  &,
  &:link,
  &:visited {
    color: $color_black;
  }
  &:hover {
    color: $link_hover;
    transition: color $hover_animate;
  }
}

.border-box {
  margin: 0 auto 80px;
  padding: 28px 0 30px;
  border: solid 1px #ebebeb;
  text-align: center;
  h3 {
    margin: 0 0 13px;
    padding: 0;
    font-size: 22px;
    line-height: 1.4;
    text-align: center;
  }
  &.narrow {
    width: 740px;
  }
  &.b-short {
    margin-bottom: 20px;
  }
  &.none {
    margin-bottom: 0;
  }
  .sup {
    font-size: 12px;
  }
}

.logo-three-col {
  margin: 0 0 70px;
  padding: 0 15px;
  li {
    display: block;
    float: left;
    width: 311px;
    margin: 0 30px 30px 0;
    padding: 0 10px 10px;
    background-color: #f5f5f5;
    text-align: center;
    &:nth-child(3n+1) {
      clear: both;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 0;
    }
    &:nth-last-child(3) {
      margin-bottom: 0;
    }
    h3 {
      margin: 0;
      padding: 15px 0;
      color: #222;
      font-size: 18px;
      text-align: center;
      line-height: 1.4;
    }
    .h3-adjust{
      padding: 12px 0 10px;
      line-height: 1;
    }
    .h3-subtxt{
      font-size: 12px;
      padding: 0;
    }
    .logo-inner {
      padding: 45px 30px 35px;
      background-color: #fff;
    }
    .img-area {
      margin: 0 0 25px;
      line-height: 0;
    }
    .img-area-adjust{
      padding: 38px 0;
    }
    .txt-area {
      height: 75px;
      margin: 0 0 25px;
      font-size: 14px;
      text-align: left;
    }
  }
}

.sns-list {
  margin: 0 0 25px;
  text-align: center;

  &.type02 {
    margin-bottom: 0;
  }
  li {
    display: inline-block;
    padding: 0 30px 0;
    img {
      display: block;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
    span {
      position: relative;
      padding-left: 15px;
      color: $color_black;
      font-size: 14px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        border: 4px solid transparent;
        border-left: 5px solid #e60012;
      }
    }
  }
}

.dl00 {
  dt {
    margin: 20px 0 15px;
    font-size: 16px;
    font-weight: bold;
    text-indent: -1.7em;
    padding-left: 1.7em;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
    .strong {
      font-weight: bold;
    }
  }
}

.dl01 {
  dt {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    text-indent: -1.7em;
    padding-left: 1.7em;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    margin-bottom: 30px;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
  }
}

.dl02 {
  dt {
    margin: 25px 0 15px 0;
    font-size: 16px;
    font-weight: bold;
    text-indent: -1.7em;
    padding-left: 1.7em;
  }
  dd {
    margin-bottom: 26px;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
    .strong {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .ddList {
    list-style-type: disc;
    margin-left: 56px;
    margin-bottom: 10px;
  }
}

.dl03 {
  &.type02 {
     > :last-child {
      margin-bottom: 0;
    }
  }
  dt {
    float: left;
    clear: both;
    padding-right:5px;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    overflow: hidden;
    margin-left: 1.5em;
    margin-bottom: 15px;
    p {
      margin-bottom: 0;
      &.strong {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .left-area {
      clear: both;
      float: left;
      width: 30%;
      margin-bottom: 20px;
    }
    .middle-area {
      float: left;
      width: 10%;
      padding-top: 30px;
    }
    .right-area {
      float: left;
      width: 60%;
      margin: 20px 0 20px;
      padding: 10px;
      border: dashed 1px #999;
    }
    .txt-large {
      font-size: 18px;
    }
  }
}


.small-arrow {
  position: relative;
  padding-left: 15px;
  font-size: 14px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    border: 4px solid transparent;
    border-left: 5px solid #e60012;
  }
}

.sitemap-wrap {
  width: 700px;
  margin: 0 auto;
  .dl_sitemap01 {
    margin-top: 0;
    width: 291px;
    &.left {
      float: left;
    }
    &.right {
      float: right;
      dt {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    dt {
      margin: 30px 0 20px;
      border-bottom: solid 1px #ebebeb;
      color: $color_black;
      font-size: 16px;
      font-weight: bold;
      &:first-of-type {
        margin-top: 0;
      }
      a {
        position: relative;
        display: inline-block;
        width: 100%;
        color: $color_black;
        &:hover {
          color: #c57800;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          margin: auto;
          vertical-align: middle;
          width: 4px;
          height: 4px;
          border: 4px solid transparent;
          border-left: 5px solid #e60012;
        }
      }
    }
    dd {
      margin: 0 0 10px 18px;
      a {
        position: relative;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        padding-left: 14px;
        background: url(/common/img/ico_arrowB03.gif) no-repeat left 0.5em;
        &:hover {
          background-image: url(/common/img/ico_arrowB02.gif);
        }
      }
      &.dd_2nd {
        margin-left: 42px;
        a {
          padding-left: 9px;
          background-image: url(/common/img/ico_arrowR01.gif);
          &:hover {
            background-image: url(/common/img/ico_arrowR02.gif);
          }
        }
      }
      &.dd_3nd {
        margin-left: 61px;
        a {
          padding-left: 9px;
          background-image: url(/common/img/ico_arrowR01.gif);
          &:hover {
            background-image: url(/common/img/ico_arrowR02.gif);
          }
        }
      }
      &.outer-link {
        a {
          &:after {
            background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
            background-size: 15px auto;
            content: "";
            position: absolute;
            top: 0;
            right: -22px;
            width: 15px;
            height: 15px;
            margin-top: 4px;
            padding: 0;
            border: none;
          }
        }
      }
      &.nonJump {
      padding-left: 15px;
      background: url(/common/img/ico_arrowB03.gif) no-repeat left 0.5em;
      }
    }
  }
}

// table
.t-standard {
  border: 1px solid #e0e0e0;
  margin-bottom: 50px;
  table-layout: fixed;
  word-break: break-all;
  width: 100%;
  &.narrow {
    margin-left: auto;
    margin-right: auto;
    width: 875px;
  }
  &.head-l {
    th {
      width: 144px;
    }
  }
  &.head-l02 {
    th {
      width: 214px;
    }
  }
  &.head-l03 {
    th {
      width: 310px;
    }
  }
  &.head-left {
    th {
      text-align: left;
    }
  }
  &.head-nowrap {
    th {
      white-space: nowrap;
    }
  }
  &.tb-guidance {
    table-layout: auto;
    th {
      width: 22%;
    }
    td:nth-of-type(1) {
      width: 17%;
      padding-left: 25px;
      padding-right: 10px;
      text-align: center;
    }
    td:nth-of-type(2) {
      width: 12%;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: top;
    }
    td:nth-of-type(3) {
      padding-left: 10px;
    }
  }
  th,
  td {
    border-bottom: 1px solid #e0e0e0;
    vertical-align: middle;
    padding: 25px;
  }
  th {
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    font-size: 16px;
    text-align: center;
  }
  td {
    text-align: left;
  }
  .b-none {
    th,
    td {
      border-bottom:none;
    }
  }
  .sup {
    font-size: 12px;
    font-weight: normal;
  }
  .td-btn {
    float: right;
  }
}

// ↓guidance.hetml使用
.guidanceTopText {
  margin: 0 auto;
  width: 655px;
  padding: 0 0 30px;
  font-size: 16px;
  .lSpace01{
    margin-left: 14px;
  }
  .strong {
    font-size: 20px;
  }
}

.t-point2 {
  width: 964px;
  height: 1px; // IE11でth内のグレーの領域がセルの高さいっぱいにならない対策
  margin: 0 15px 70px 45px;
  table-layout: fixed;
  &.b-short {
    margin-bottom: 30px;
  }
  .space-tb {
    margin: 25px 0;
  }
  tr:first-child {
    th,
    td {
      border-top: 1px solid #e0e0e0;
    }
  }
  th {
    border-bottom: 1px solid #e0e0e0;
    width: 170px;
    height: 100%;
    .ttl-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 25px 20px;
      background-color: #F5F5F5;
      font-size: 16px;
      line-height: 1.4;
      vertical-align: middle;
      text-align: center;
      height: calc(100% - 20px);
      margin: 10px 0;
    }
  }
  td {
    padding-left: 20px;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: middle;
  }
}

.t-cell {
  width: 875px;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  margin: auto;
  text-align: center;
  th {
    border-bottom: 1px solid #e0e0e0;
  }
  td {
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
  }
}

.point-two-col {
  display: flex;
  flex-wrap: wrap;
  li {
    text-indent: -1em;
    padding-left: 1em;
    &:nth-child(2n+1) {
      width: 52%;
    }
    &:nth-child(2n) {
      width: 48%;
    }
  }
}

.point-three-col {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  text-align: left;
  li {
    width: 33%;
    text-indent: -1em;
    padding-left: 1em;
  }
}

// newsereleace
.newsreleace {
  &-tab-btn {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: auto;
    padding-bottom: 40px;
    flex-wrap: wrap;
    li {
      padding: 10px 5px;
      a {
        border: 1px solid #e0e0e0;
        color: $color_black;
        display: block;
        font-size: 15px;
        padding: 10px 30px;
        text-align: center;
        &:hover {
          color: $link_hover;
          transition: color $hover_animate;
        }
      }
      &.on {
        a {
          background-color: $color_red;
          border-color: $color_red;
          color: #fff;
        }
      }
    }
  }

  &-list {
    margin-bottom: 90px;
    li {
      &.new {
        dt {
          position: relative;
          &::before {
            color: $color_red;
            content: "NEW";
            font-size: 12px;
            display: block;
            position: absolute;
            top: 0;
            left: -35px;
          }
        }
      }
    }
    dl {
      display: flex;
      align-items: flex-start;
      margin-top: 25px;
      padding: 0 60px 25px 100px;
      border-bottom: 1px dotted #e7e7e7;
      dt {
        color: #aaa;
        margin-right: 20px;
      }
      dd {
        word-break: break-all;
      }
    }
  }
}

.movie-area {
  margin-bottom: 30px;
  text-align: center;
  .txt-area {
    margin-top: 10px;
    &.movie_caption {
      margin: 0 auto;
      width: 620px;
      small {
        display: block;
        font-weight: normal;
      }
    }
  }
}

.movie_profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto 50px;
  width: 620px;
  figure {
    width: 120px;
  }
  p {
    width: 495px;
    font-size: 12px;
    em {
      display: block;
      font-style: normal;
      font-weight: bold;
    }
    small {
      font-weight: normal;
    }
  }
}

.graphic-four-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 20px;
  width: 827px;
  &::before {
    content:"";
    display: block;
    width:184px;
    order:1;
  }
  &::after {
    content:"";
    display: block;
    width:184px;
  }
  li {
    margin-bottom: 60px;
    width: 184px;
  }
  dt {
    background-color: $color_black;
    color: #fff;
    padding: 5px 0;
    text-align: center;
  }
  dd {
    border: 1px solid $color_black;
    border-top: 0 none;
    min-height: 259px;
  }
}

// 画像ボックス　画像だけを外してもレイアウトを保てるモジュール
.img-box-l,
.img-box-r,
.img-box-r02 {
  display: block;
  min-height: 1%;
  &::after {
    clear: both;
    content:".";
    display: block;
    height: 0;
    visibility: hidden;
  }
}
.img-box-l .flt-img {
  display: inline;
  float: left;
  margin-right: 20px;
}
.img-box-r .flt-img {
  display: inline;
  float: right;
  margin-left: 20px;
}

.img-box-r02 {
  .flt-text {
    float: left;
  }
  .flt-img {
    display: inline;
    float: right;
    margin-left: 20px;
  }
}

// history
.history {
  padding: 0 100px;
  dt {
    clear: both;
    float: left;
    width: 7em;
    font-weight: bold;
  }
  dd {
    margin-bottom: 10px;
    padding: 0 0 10px 7.5em;
  }
}

// map modal
#TB_overlay,
#TB_window {
  z-index: 10001;
}
#TB_window {
  width: 600px !important;
  left: 50%;
  margin-left: -300px;
}
#TB_title {
  height: auto;
  &::after {
    content:" ";
    display:block;
    clear:both;
  }
}

.text-two-col-vertical {
  & > * {
    float: left;
    margin-right: 50px;
    &:last-child {
      margin-right: 0;
    }
  }
}

// news-info
.news-info {
  padding: 0 50px;
  dt {
    clear: both;
    float: left;
    width: 9em;
  }
  dd {
    margin-bottom: 10px;
    padding: 0 0 10px 9.5em;
  }
  .info-title {
    border:solid 1px #c9c9c9;
    line-height: 1.78;
    padding:5px;
    text-align: center;
  }
  .info-box-line01 {
    border: solid 1px #c9c9c9;
    padding: 10px;
  }
}

.border-double-box {
  margin-bottom: 40px;
  padding: 4px;
  text-align: center;
  &,
  &-inner {
    border: 1px solid #908b8a;
  }
  &.narrow {
    width: 640px;
    margin-left: auto;
    margin-right: auto;
  }
  &-ttl {
    border-bottom: 1px solid #908b8a;
    padding: 10px;
    margin-bottom: 10px;
  }
}

// modal
#cboxOverlay {
  background-color: #000;
}
#cboxContent,
#cboxLoadingGraphic,
#cboxLoadedContent {
  background-color: #fff;
}
#cboxContent {
  margin-top: 0;
}
#cboxClose {
  background: none;
  position: absolute;
  top: 25px;
  right: 25px;
  text-indent: -9999px;
  overflow: hidden;
  width: 27px;
  height: 20px;
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: #120603;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 9px;
    left: 0;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(135deg);
  }
  &:hover {
    opacity: $hover_opacity;
    transition: opacity $hover_animate;
  }
}

.modal {
  display: none;
  &-contents {
    padding: 30px 35px 50px 50px;
    width: 946px;
    .date {
      margin-bottom: 2px;
    }
    .img-area {
      margin-right: 30px;

      img {
        border: 1px solid #e0e0e0;
      }
    }
    .txt-area {
      .copy {
        font-size: 28px;
        margin-top: 50px;
        text-align: center;
      }
      small {
        display: block;
        font-size: 10px;
        margin-top: 125px;
        text-align: center;
      }
    }
  }
  &-two-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* form */
.form-wrap {
  padding: 50px;
  text-align: left;
  width: 640px;

  #mailformpro {
    padding: 0;

    dt {
      border-top: 0 none;
      clear: both;
      float: none;
      font-size: 20px;
      margin-bottom: 5px;
      padding: 0;
      text-align: left;
      width: auto;
    }

    dd {
      border-top: 0 none;
      font-size: 16px;
      line-height: normal;
      margin-bottom: 30px;
      padding: 0;
    }

    label {
      border-radius: 0;
      margin: 0;
      display: block;
      white-space: normal;
    }

    input,
    select {
      appearance: none;
      -webkit-appearance: none;
      background: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 0;
      box-shadow: none;
      color: #120603;
      display: block;
      font-family: $font-family-min;
      font-size: 16px;
      margin: 0;
      padding: 20px;
      width: 100%;
      max-width: 100%;

      &.problem {
        background: #ffeded;
      }
    }

    input {
      &[type="file"]{
        line-height: 1;
      }
    }

    select::-ms-expand {
      display: none;
    }

    .select-wrap {
      position: relative;
      width: 173px;

      &::after {
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        content: "";
        height: 10px;
        position: absolute;
        right: 15px;
        top: 44%;
        transform: rotate(-315deg) translateY(-53%);
        width: 10px;
      }
    }

    .radio-wrap {
      label {
        padding: 0 35px;
        position: relative;

        &::before {
          background-color: #fff;
          border: 1px solid #e0e0e0;
          border-radius: 50%;
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 30px;
          height: 30px;
        }
      }

      input:checked + label::after,
      input:checked + input[type="hidden"] + label::after {
        background-color: #e60012;
        border-radius: 50%;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }
      input[type="radio"] {
        display: none;
      }
    }

    .horizontal-wrap {
      display: flex;
      justify-content: space-between;
    }

    .postal-wrap {
      align-items: center;

      input {
        width: 130px;
        &#postal-code {
          width: 100px;
        }
      }

      .select-wrap {
        margin-left: 80px;
      }
    }

    .text-wrap {
      padding: 0 10px;
    }

    .file-wrap {
      label {
        display: block;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .btn-wrap {
      text-align: center;
    }

    .btn {
      background: #e60012;
      border: 0 none;
      border-radius: 0;
      font-size: 16px;
      margin-bottom: 10px;
      padding: 12px 50px;
      text-shadow: none;

      &:hover {
        background: #ee4d5a;
        box-shadow: none;
      }
    }

    // error
    div.mfp_err {
      background: none;
      margin: 5px 0 0;
      color: #e60012;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      padding: 0;
    }
  }
}

// 確認画面
div#mfp_phase_confirm h4 {
  font-size: 24px;
}

table#mfp_confirm_table {
  tr {
    &.mfp_achroma,
    &.mfp_colored {
      background: none;
    }

    th,
    td {
      border-top: 0 none;
      border-bottom: 1px dotted #e0e0e0;
      font-size: 16px;
      padding: 10px;
    }

    th {
      text-align: right;
      width: 160px;
    }

    td {
      word-break: break-all;
    }
  }
}

div.mfp_buttons {
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  font-size: 16px;
  padding: 30px 25px 0;

  .mfp_element_button {
    background: #fff;
    border: 0 none;
    border-radius: 0;
    font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "游明朝", YuMincho, serif;
    padding: 12px 40px;
    text-shadow: none;
    transform: skewX(-25deg);
    width: 230px;

    &:hover {
      box-shadow: none;
      transition: background 0.3s ease;
    }

    span {
      display: block;
      transform: skewX(25deg);
      position: relative;
      padding-left: 15px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        border: 4px solid transparent;
        border-left: 5px solid #fff;
      }
    }

    &#mfp_button_send {
      background: #e60012;
      color: #fff;

      &:hover {
        background: #ee4d5a;
      }
    }

    &#mfp_button_cancel {
      border: 1px solid #e0e0e0;

      &:hover {
        background: #f9f9f9;
      }

      span {
        &::before {
          border-left: 5px solid #120603;
        }
      }
    }
  }
}

// 完了画面
.form-completion-wrap {
  margin-bottom: 80px;

  .btn-wrap {
    margin-top: 50px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 50px;
  }
}


/* ============================================
	English site
============================================ */

#header_en {
	position: relative;
	overflow: hidden;
	border-top: 3px solid #e70a1b;
  min-width: 1044px;
	text-align: right;
	zoom: 1;
  .header-en-inner {
    width: 960px;
    margin: 0 auto;
  }
  h1 {
    float: left;
    width: 390px;
    margin: 21px 0 0 25px;
    padding: 0;
    line-height: 0;
    text-align: left;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .headUtility {
    position: relative;
    overflow: hidden;
    float: right;
    margin-bottom: 0;
    padding: 30px 0 25px;
    width: 120px;
    zoom: 1;
    text-align: right;
    .utilityNavi {
      position: relative;
      clear: both;
      overflow: hidden;
      zoom: 1;
      height: auto;
      li {
        &.uNav06 {
          margin-right: 0;
          width: 105px;
        }
        a {
          position: relative;
          display: block;
          padding-left: 15px;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            vertical-align: middle;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 5px solid #e60012;
          }
        }
      }
    }
  }
}

#contents_en {
	width: 960px;
  margin: 0 auto;
	padding: 0 0 90px;
  .main_english {
    line-height: 1.75;
    font-size: 14px;
    clear:both;
  }
}

div #box_main_en {
  width: 900px;
  height: auto;
  margin: 0 auto 80px;
  padding:93px 0 0 0;
  #box_eng_left {
    float:left;
    width:420px;
    height: auto;
    padding:0px 0 0 0;
  }
  #box_eng_right {
    float:right;
    width:420px;
    height: auto;
    padding:0px 0 0 0;
  }
  .strong {
    font-size: 16px;
    font-weight: bold;
    .mt5 {
      margin-top: 5px;
    }
  }
  .left {
    float: left;
  }
  .pdf-icon {
    padding: 190px 0 0;
  }
}

.buttons-en {
  text-align: center;
  span {
    &:first-of-type {
      padding-right: 15px;
    }
    &:nth-of-type(3) {
      padding-left: 15px;
    }
  }
}

#footWrap_english {
	clear: both;
	position: relative;
	width: 100%;
	height: auto;
}

#footer_english {
  position: relative;
  background: url(/common/img/footer/foot_bg01.gif) repeat-x left top;
  min-width: 1044px;
  margin: 0 auto;
  padding: 0;
  height: auto;
  text-align: left;
  .footBottom {
    clear: both;
    position: relative;
    width: 960px;
    height: auto;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    line-height: 38px;
    font-size: 10px;
    text-align: right;
  }
  .footLogo {
    float: left;
    text-align: left;
  }
}


/* ============================================
	クレド credo
============================================ */
#credo {
  #header {
    .pc {
      .logo {
        img {
          width: 180px;
          height: auto;
        }
      }
    }
  }
  #footer {
    padding-top: 1px;
    border-top: solid 1px #ebebeb;
    .footer-right {
      width: 100%;
      text-align: center;
    }
  }
}


/* ============================================
	ブランド広告 20241029追加
============================================ */
.BrandAnchor{
  &Box {
    display: flex;
    margin-bottom: 25px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  
  }
  &Item {
    width: 100%;
    a {
      display: block;
      position: relative;
      padding: 20px;
      padding-left: 60px;  
      font-size: 14px;
      color: #120603;
      &::before {
        content: "";
        position: absolute;
        top: 35%;
        left: 30px;
        width: 12px;
        height: 12px;
        border-right: 2px solid #e60012;
        border-bottom: 2px solid #e60012;
        transform: rotate(45deg);
      }
      &:hover {
        color: #e60012;
      }
    }
  }
}
.sponsor {
  &Box {
    display: flex;
    justify-content: space-between;
    width: 670px;
    margin: 0 auto 50px;
  }
  &List {
    width: 320px;
    img {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }
    .tvTitle {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
    }
    .sponsorship {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .sponsorLink {
      display: inline-block;
      position: relative;
      &:after {
        background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
        background-size: 15px auto;
        content: "";
        position: absolute;
        top: 0;
        right: -22px;
        width: 15px;
        height: 15px;
        margin-top: 4px;
        padding: 0;
        border: none;
      }
    }
  }
}
/* ============================================
	ページバナー 20250120追加
============================================ */
.bnr_content{
  width: 100%;
  max-width:440px;
  margin: 0 auto 50px;
  box-sizing: border-box;
  img{
    width: 100%;
  }
}