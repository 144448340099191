/* properties for SDGs page
==================================================================================================== */
// head section
.sdgs-header{
	margin-bottom:30px;
	.sdgs-logo{
		img{
			width:230px;
			height:auto;
		}
	}
	h3{
		font-size:16px;
		margin-bottom:15px;
	}
	h2.line{
		text-align:left;
	}
}

// sdgs bout
.sdgs-bout{
	height: 550px;
	font-size: 16px;
	.sdgs-bout-left{
		width: 100%;
		float: none;
		height: auto;
		margin-bottom: 0;
		font-size: 14px;
		box-shadow: 0px 0px 10px 5px #cfcfcf inset;
		&:nth-of-type(1){
			height: 50px;
			a{
				line-height: 50px;
				padding: 15px 29%;
			}
			a.arrow::before{
				margin-top: 20px;
			}
		}&:nth-of-type(2){
			height: 50px;
			margin-top: 15px;
			a{
				line-height: 50px;
				padding: 15px 20%;
			}
			a.arrow::before{
				margin-top: 20px;
			}
		}&:nth-of-type(3){
			height: 50px;
			margin-top: 15px;
			a{
				line-height: 50px;
				padding: 15px 35%;
			}
			a.arrow::before{
				margin-top: 20px;
			}
		}
	}
	.sdgs-bout-right{
		width: 100%;
		margin-bottom: 5px;
		font-size: 14px;
		float: none;
		margin-left: 0;
		li{
			height: auto;
			padding-top: 0;
		}
		a{
			padding-left: 20px;
			font-size: 100%;
		}
		.sdgs-title{
			font-size: 100%;
			margin-top: 10px;
		}
	}
}


// movie area
.sdgs-mov{
	width: auto;
	padding: 10px;
}
.movie-area{
	&.sdg-movie{
		width:auto; 
		margin:0 auto 10px;
	}
	.txt-area{
		font-size:14px;
	}
}
.sdgs-feature{
	width:auto;
	margin:0 auto 50px;
	padding:15px 20px 15px;
	.sdgs-goals{
		width:auto;
		li{
			width:calc(25% - 8px);
			margin:4px;
		}
	}
}

// content container
.sdgs-content{
	width:auto;
	margin:0 auto 30px;
	h2.h-center{
		margin-bottom: 1%;
	}
	h3{
		font-size:16px;
		margin-bottom:15px;
	}
	.logo{
		img{
			height:auto;
		}
		&.bio img{width:210px;}
		&.kinomori img{width:210px;}
		&.kakeibo img{width:270px;}
		&.bousai img{width:206px;}
		&.soleco img{width:170px;}
		&.soleiyu img{width:226px;}
		&.soleco-plus img{width:170px;}
		&.soleco-ev-share img{width:170px;}
		&.zeh-m img{width:170px;}
		&.co2 img{width:250px;}
	}
	.sdg-activity-block{
		width:auto;
		margin:0 auto 15px;
		display:block;
		h4{
			font-size:14px;
			margin-bottom:5px;
		}
		.desc{
			width:auto;
			.leading{
				font-size:16px;
				margin:0 0 10px;
			}
			dl{
				dt{
					+ dt{margin-top:12px;}
				}
				dd{
					margin-bottom:12px;
					&:last-of-type{
						margin-bottom:12px;
					}
				}
				
			}
			p:last-child{
				margin-bottom:15px;
			}
		}
		.img{
			width:auto;
			.caption{
				padding-top:10px;
			}
		}
		.soleco-plus-img{
			display: block;
			img{
				width: auto;
				margin: auto;
			}
		}
	}
	.t-point2{
		margin:0 0 20px;
		border:1px #E0E0E0 solid;
		border-width:1px 0;
		th{
			padding-top:10px;
		}
		td{
			padding:10px 0;
		}
	}
	.link{
		a{
			display:block;
			width:220px;
			margin:0 auto;
		}
		text-align:center;
		.btn{margin:0 auto 20px;}
	}
	.related-links{
		padding:5px 15px;
		ul{
			li{margin:10px 0;
				a[target=_blank]{
					padding-left:20px;
					background-size:14px auto;
					background-position:0 0.3em;
					display:block;
				}
			}
		}
	}
	.soleco-figure{
		h3{
			line-height:1.75;
		}
		padding:25px 30px;
		margin-bottom:15px;
		img{
			margin-bottom:10px;
		}
	}
	.soleiyu-figure{
		margin-bottom:20px;
	}
	sub{vertical-align:baseline; font-size:75%;}
	&.outro{
		margin:40px auto 50px;
		h2{
			text-align:left;
		}
		figure{
			padding:0 20px;
		}
	}
	.t-sdgs{
		width: auto;
		th{
			display: block;
			border-right: none;
			font-size: 16px;
		}
		td{
			display: block;
			padding-left: 0;
			line-height: normal;
		}
	}
	br.sp {
		display: none;
	}
}
.sdgs-large{
	box-shadow: 0px 0px 10px 5px #cfcfcf inset;
	padding: 20px;
	margin: 50px auto 15px;
	h2.h-center{
		margin: 0 0 5%;
	}
}
.title-bottom{
	width: auto;
	font-size: 18px;
}
.break-line.sdgs{margin-bottom:20px;}

