/* properties for SDGs page
==================================================================================================== */
// head section
.sdgs-header{
	.list-three-col{
		margin-bottom:0;
	}
	h2.h-center{
		padding-left:.5em;
	}
	.sdgs-logo{
		margin-bottom:40px;
	}
}

// movie area
.sdgs-mov{
	background:#f5f5f5;
	width: 994px;
	margin: 0 auto 10px;
	padding: 30px 90px 10px;
}
.movie-area{
	&.sdg-movie{
		width:640px; 
		margin:0 auto 40px;
	}
	.txt-area{
		margin-bottom:10px;
		font-size:16px;
		font-weight:bold;
	}
	.movie-intro{
		text-align:left;
	}
}
.sdgs-feature{
	width:994px;
	margin:0 auto 50px;
	padding:30px 90px 60px;
	h4{
		font-size:16px;
		margin-bottom:10px;
		text-align:center;
	}
	.sdgs-goals{
		width:616px;
		margin:-10px auto 0;
		display:flex;
		justify-content:flex-start;
		flex-wrap:wrap;
		li{
			width:84px;
			margin:8px;
			img{
				display:block;
				width:100%;
				height:auto;
			}
		}
	}
}

// sdgs bout
.sdgs-bout{
	height: 750px;
	a {
		color: #222;
	}
	a.arrow::before{
		position: absolute;
		content: "";
		width: 10px;
		height: 10px;
		margin-top: 6px;
		margin-left: -20px;
		box-sizing: border-box;
		border: 0 solid transparent;
		border-left: none;
		border-bottom: 2px solid #e60012;
		border-right: 2px solid #e60012;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	.sdgs-bout-left{
		width: 500px;
		float: left;
		border: 1px solid #D6D6D6;
		text-align: center;
		margin-bottom: 50px;
		border-radius:10px;
		box-shadow: 0px 0px 20px 5px #cfcfcf inset;
		&:nth-of-type(1){
			height: 450px;
			a{
				line-height: 450px;
				padding: 215px 178px;
			}
			a.arrow::before{
				margin-top: 220px;
			}
		}
		&:nth-of-type(2){
			height: 100px;
			a{
				line-height: 100px;
				padding: 40px 148px;
			}
			a.arrow::before{
				margin-top: 45px;
			}
		}
		&:nth-of-type(3){
			height: 50px;
			a{
				line-height: 50px;
				padding: 15px 199px;
			}
			a.arrow::before{
				margin-top: 20px;
			}
		}
		a {
			font-size: 20px;
			.arrow::before{
				margin-top: 10px;
			}
		}
	}
	.sdgs-bout-right{
		width: 500px;
		float: right;
		margin-bottom: 50px;
		margin-left: 20px;
		li{
			border-bottom: 1px solid #D6D6D6;
			height: 50px;
			padding-top: 10px;
			padding-left: 10px;
		}
		a{
			font-size: 16px;
			padding-left: 40px;
		}
		.sdgs-title{
			font-size: 18px;
		}
	}
}

// content container
.sdgs-content{
	width:934px;
	margin:0 auto 30px;
	h3{
		margin-bottom:30px;
	}
	.sdg-activity-block{
		width:900px;
		margin:0 auto 25px;
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
		h4{
			width:100%;
			font-size:16px;
		}
		.desc{
			width:515px;
			.leading{
				font-size:21px;
				margin:-0.25em 0 15px;
			}
			dl{
				dt{
					font-weight:bold;
					&::before{
						content:'■';
					}
					+ dt{margin-top:20px;}
				}
				dd{
					margin-bottom:20px;
					&:last-of-type{
						margin-bottom:0;
					}
				}
				
			}
			p:last-child{
				margin-bottom:0;
			}
		}
		.img{
			width:360px;
			img{
				display:block;
				width:100%;
				height:auto;
			}
			.frame{
				border:1px #ebebeb solid;
				padding:10px 60px;
				background:#fff;
			}
			.caption{
				font-size:12px;
				padding-top:15px;
			}
		}
		.soleco-plus-desc{
			width: 360px;
		}
		.soleco-plus-img{
			width: 515px;
			display: flex;
		}
		.img-2 {
			width: 934px;
		}
	}
	.t-point2{
		margin:0 0 30px;
		width:100%;
		table-layout:auto;
		td{
			padding:25px;
			vertical-align:top;
			line-height:1.65;
			.td-box{
				margin-bottom:15px;
				position:relative;
				padding-left:80px;
				min-height:75px;
				&:last-child{
					margin-bottom:0;
				}
			}
			.goal-icn{
				width:66px;
				height:66px;
				position:absolute;
				left:0;
				top:5px;
				img{
					width:66px; 
					height:auto;
				}
			}
			b{
				display:inline-block;
				margin-top:10px;
				&:first-of-type{margin-top:0;}
			}
		}
	}
	.link{
		text-align:center;
		.btn{margin:0 25px;}
	}
	.related-links{
		border-top: solid 1px #D6D6D6;
		border-bottom: solid 1px #D6D6D6;
		padding:15px 35px;
		ul{
			li{margin:10px 0;
				a[target=_blank]{
					padding-left:25px;
					background:url("/common/img/icon_doc_blue.png") no-repeat left 0;
					background-size:16px auto;
				}
			}
		}
	}
	.soleco-figure{
		border:10px #f5f5f5 solid;
		background:#fff;
		padding:30px 95px;
		text-align:center;
		margin-bottom:25px;
		img{
			width:100%; 
			height:auto;
			display:block;
			margin-bottom:15px;
		}
		figcaption{
			text-align:left; 
			font-size:12px;
		}
	}
	sub{vertical-align:baseline; font-size:75%;}
	&.outro{
		margin:50px auto 70px;
	}
	.p-center{
		text-align: center;

	}
	.t-sdgs{
		margin: 0 auto 20px;
		width: 875px;
		th{
			text-align: center;
			width: 230px;
			border-right: 1px solid #CCC;
			margin: auto;
			vertical-align: middle;
		}
		td{
			padding-left: 30px;
			line-height: 2.25;
			.figcaption{
				font-size: 12px;
				display: inline-block;
				margin: 0 0 5px 22px;
			}
			.all-figcaption{
				font-size: 12px;
				display: inline-block;
				margin: 17px 0 0 0;
			}
		}
	}
	.logo{
		&.soleco img{width: 250px;}
		&.soleco-ev-share img{width: 250px;}
		&.soleiyu img{width: 300px;}
		&.kinomori img{width: 300px;}
		&.soleco-plus img{width: 250px;}
	}
	.caution{
		font-size: 12px;
    text-align: right;
    margin: 0px 0 24px 0;
    width: 100%;
    max-width: 580px;
    display: inline-block;
	}
}
.sdgs-large{
	border: 1px solid #D6D6D6;
	padding: 20px 10px 10px;
	border-radius: 10px;
	box-shadow: 0px 0px 20px 5px #cfcfcf inset;
	margin: 60px auto 30px;
}
.break-line.sdgs{margin-bottom:40px;}
.btn.btn-reverse{
	background-color:#fff;
	border:1px #000 solid;
	&:link,&:visited{color:#000;}
	span{
		&::before{
			border-left-color:#000;
		}
	}
	&:hover{background:#f5f5f5;}
}
.title-bottom{
	border-bottom:1px #000 solid;
	width: 400px;
	margin: 0 auto 20px;
}
.zeh-caution{
	font-size: 12px;
	display: inline-block;
	margin: -7px 0 5px 0;
}
.sdgs-caution{
	font-size: 11px;
	display: inline-block;
	padding: 0;
	margin: 0;
}

