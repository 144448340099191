/* properties for diversity page
==================================================================================================== */
.page-diversity {
  h1.mv {
    height: 300px;
    line-height: 300px;
    color: #fff;
    background: center no-repeat url(../img/company/diversity/img-00.jpg);
  }
  h2.h-center {
    margin-top: 60px;
  }
  .mod_bg_g {
    padding: 10px 0;
    background: #f5f5f5;
  }
  .leadtext {
    font-weight: bold;
    text-align: center;
  }
  .visual {
    padding: 20px 30px 35px;
    text-align: center;
  }
  .list-three-col {
    &.mod_localnav {
      li {
        a {
          padding: 15px 20px;
          &::before {
            display: none;
          }
          &::after {
            content: '';
            position: absolute;
            top: 17px;
            right: 20px;
            display: block;
            width: 10px;
            height: 10px;
            border-right: solid 2px #e60012;
            border-bottom: solid 2px #e60012;
            transform: rotate(45deg);
          }
        }// a
        &.mod_longtext {
          a {
            font-size: 10px;
          }
        }
      }// li
      &.mod_col4 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        li {
          margin: 0 !important;
          width: 24%;
        }
      }//&.mod_col4
    }//&.mod_localnav
  }//.list-three-col
  .image-col2 {
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      width: 49%;
      img {
        width: 100%;
      }
    }
  }//.image-col2
  .t-point2 {
    margin-left: 30px;
    small {
      margin-top: 5px;
      display: block;
      font-size: 10px;
      font-weight: normal;
    }
    td {
      vertical-align: top;
    }
  }//.t-point2
  .card_layout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 30px;
    li {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
      width: 308px;
      background: #FDEAEB;
      border: solid 10px #FDEAEB;
      em {
        display: block;
        padding: 15px 0;
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        text-align: center;
      }
      .card-body {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        justify-content: space-between;
        padding: 20px;
        background: #fff;
        figure {
          margin-right: 20px;
        }
        p {
          margin-bottom: 0;
          width: 200px;
          font-size: 12px;
        }
      }
    }// li
    &.mod_col2 {
      padding: 0 194px;
    }
  }//.card_layout
}//.page-diversity