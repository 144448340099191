/* properties for inquiry pages
==================================================================================================== */
// block navigation
.inquiry-menu{
	margin-bottom:40px;
	padding-top:20px;
	&-item{
		width:calc(50% - 8px);
		margin:0 15px 15px 0;
		padding:10px 8px;
		background:#f3f3f3;
		h2{
			font-size:15px;
			margin-bottom:5px;
			padding:0 5px;
			line-height:1.4;
			min-height:2.6em;
			display:flex;
			align-items:center;
			justify-content:center;
		}
		.icn{
			padding:5px; 
			margin-bottom:10px;
			img{
				margin:0 auto; 
				width:80px;
			}
		}
		h3{
			font-size:12px;
			text-align:left;
			line-height:1.5;
		}
		ul{
			li{
				font-size:12px;
				line-height:1.4;
				a{
					padding-left:12px;
					&::before{
						top:0.9em;
						left:0;
						margin-top: -8px;
						width:10px;
						height:10px;
						border:4px solid rgba(0,0,0,0);
						border-left:6px solid #e60012;
					}
				}
			}
		}
		&:nth-child(3n){
			margin-right:15px;
		}
		&:nth-child(2n){
			margin-right:0;
		}
	}
}
// extra
h2{
	&.level-1{
		margin-bottom:15px;
		font-size: 14px;
		line-height: 1.5;
		text-align:left;
		span{
			display:inline;
			font-size:12px;
		}
	}
}
.narrow-area{
	width:auto;
	.list-three-col{
		margin:0 0 20px;
		li{
			&.b-none{border:0 none;
				a{
					display:inline-block; 
					border:1px #e0e0e0 solid;
					padding-left:40px;
					padding-right:40px;
					&::before{
						position:relative;
						left:auto;
						top:-2px;
						margin:0;
						display:inline-block;
					}
				}
			}
		}
	}
}