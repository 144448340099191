/* properties for inquiry pages
==================================================================================================== */
// block navigation
.inquiry-menu{
	margin-bottom:50px;
	&-list{
		display:flex; 
		flex-wrap:wrap; 
		justify-content:flex-start;}
	&-item{
		width:calc(100% / 3 - 15px); 
		margin:0 22px 22px 0; 
		padding:20px;
		background:#f3f3f3;
		h2{
			font-size:18px;
			margin-bottom:10px;
			letter-spacing:-0.025em;
		}
		.icn{
			background:#fff; 
			padding:10px; 
			margin-bottom:15px;
			img{
				display:block; 
				margin:0 auto; 
				width:150px; 
				height:auto;
			}
		}
		h3{
			font-size:14px;
			padding:0;
			margin-bottom:10px;
		}
		ul{
			margin-bottom:10px;
			li{
				margin-bottom:5px;
				a{
					display:inline-block;
					padding-left:20px;
					color:#120603;
					position:relative;
					&:hover{
						color:#e60012;
					}
					&::before{
						position:absolute;
				    content: "";
    				top:1em;
    				left:0;
    				margin-top: -8px;
				    width:12px;
    				height:12px;
   					border:6px solid rgba(0,0,0,0);
						border-left:8px solid #e60012;
					}
				}
			}
		}
		&:nth-child(3n){
			margin-right:0;
		}
	}
	&-notes{font-size:12px;}
}
// extra
h2{
	&.level-1{
		margin-bottom:15px;
		font-size: 20px;
		line-height: 1.4;
	}
}
.narrow-area{
	width:875px;
	margin-left:auto;
	margin-right:auto;
}