// =========================================
//   main
// =========================================
#main {
  padding-top: 5%;
}


// =========================================
//   modules
// =========================================
h1 {
  margin: 0 0 5%;
  padding: 0;
  font-size: 25px;
  line-height: 1.4;
  text-align: center;
  &.line {
    margin: 0 0 5%;
    padding: 0 0 5%;
    border-bottom: solid 1px #E6E6E6;
  }
  &.line-sp {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5%;
  }
  &.b-wide {
    margin-bottom: 5%;
  }
  span {
    display: block;
    margin: 0 0 0.5%;
    font-size: 17px;
    &.let-space {
      font-size: 16px;
      margin-bottom: 2%;
      letter-spacing: 2px;
    }
    &.h-box {
      display: block;
      width: 70%;
      margin: 0 auto;
      padding: 10px 0;
      background-color: #447f4b;
      color: #fff;
      font-size: 22px;
      font-weight: normal;
      letter-spacing: -1px;
    }
  }
  &.page404 {
    margin-bottom: 7%;
    padding-top: 5%;
    font-size: 58px;
    line-height: 1.3;
    letter-spacing: 5px;
    span {
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 2px;
    }
  }
}
.only-txt{
  margin: 23px auto 5.5%;
}
.h1_mb_large{
  margin-bottom: 8%;
}
h2 {
  margin: 0 0 5%;
  padding: 0 0 0 0;
  font-size: 16px;
  line-height: 1.7;
  text-align: center;
  &.line {
    margin: 0 0 5%;
    padding: 0 0 5% 0;
    border-bottom: solid 1px #ebebeb;
  }
  &.line-sp {
    border-bottom: 1px solid #ddd;
    padding-bottom: 5%;
  }
  &.sp-line-none {
    margin: 0 0 5%;
    padding: 0 0 0 0;
    border-bottom: none;
  }
  &.h-center {
    text-align: center;
  }
  &.h-large {
  margin: 0 0 2%;
  font-size: 20px;
  }
  &.b-short {
    margin-bottom: 2%;
  }
  &.b-tall {
    margin-top: 50px;
  }
  span {
    display: block;
    padding-right: 0;
    font-size: 14px;
    font-weight: normal;
    vertical-align: baseline;
    line-height: 1.0;
  }
}
.h2subtxt{
  font-size: 12px;
}
.h2subtxt_sp_center{
  text-align: center;
}
h3 {
  margin: 0 0 2%;
  padding: 0 0 0 0;
  font-size: 15px;
  line-height: 1.4;
  text-align: center;
  &.h-center {
    text-align: center;
  }
  &.h-large {
    margin: 0 0 2%;
    font-size: 20px;
  }
  .sp-inline {
    display: inline-block;
  }
  span {
    display: block;
    padding: 1% 0 0;
    font-size: 13px;
    font-weight: normal;
    &.red {
      display: inline;
      padding: 0;
      font-size: 15px;
      color: $color_red;
      font-weight: bold;
    }
  }
}

.main-img {
  margin: 0 0 5%;
  line-height: 0;
  text-align: center;
  &.b-short {
    margin-bottom: 5%;
  }
  &.none {
    margin-bottom: 0;
  }
  &.space-b {
    margin-bottom: 10%;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;

    &.w400 {
      width: 95%;
    }
  }
}

main p {
  margin: 0 0 5%;
  font-size: 14px;
  line-height: 1.78;
  &.leading {
    margin-bottom: 5%;
  }
  &.b-short {
    margin-bottom: 5%;
  }
  &.b-short02 {
    margin-bottom: 2%;
  }
  &.b-none {
    margin-bottom: 0;
  }
  &.space-b {
    margin-bottom: 7%;
  }
  &.space-l {
    margin-left: 0;
  }
  &.space-l2 {
    margin-left: 0;
  }
  &.txt-left {
    text-align: left;
  }
  &.txt-right {
    text-align: right;
  }
  &.none {
    margin-bottom: 0;
  }
}

a {
  &:hover {
    img {
      opacity:0.7;
      filter:alpha(opacity=70);
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      -o-transition: 0.4s;
      -ms-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

.space-lr {
  padding: 0 0;
}

.space-lr02 {
  padding: 0 4%;
}

.space-lr03 {
  padding: 0 10px;
}

.space-lr04 {
  padding: 0;
}

.space-lr05 {
  padding-left: 10px;
  padding-right: 10px;
}

.js-tab-contents {
  display: none;
}

.w-short {
  width: 100%;
  margin: 0 auto;
}

.txt-center {
  text-align: center;
}

.txt-large {
  font-size: 16px;
}

.list-two-col {
  margin: 0 0 10%;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 2.5%;
    border: solid 1px #e0e0e0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    &:nth-child(odd) {
      clear: both;
    }
    &:nth-child(2n) {
      float: right;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 2.5%;
    }
    a {
      position: relative;
      display: block;
      padding: 12px 10px 10px 25px;
      color: #222;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        color: #e60012;
      }
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 10px;
        margin-top: -5px;
        box-sizing: border-box;
        width: 7px;
        height: 10px;
        border: 5px solid transparent;
        border-left: 7px solid #e60012;
      }
      &.li-a {
        padding: 12px 10px 10px 25px;
      }
      span {
        vertical-align: 1px;
        padding-right: 8px;
        font-size: 12px;
        font-weight: normal;
        &.blo {
          vertical-align: 0;
          display: block;
          line-height: 1.0;
        }
      }
      &.on {
        color: #e60012;
      }
    }
    .height-adjust{
      padding: 12px 10px 10px 25px;
    }
  }
}

.list-three-col {
  margin: 0 -2.5% 2.5%;
  &.space-t {
    margin-top: 7%;
  }
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 0;
    border: none;
    border-top: solid 1px #e0e0e0;
    border-bottom: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      border-bottom: solid 1px #e0e0e0;
    }
    &.b-none {
      margin-bottom: 0;
    }
    a {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      padding: 12px 2.5% 12px 6.6%;
      color: #222;
      font-size: 13px;
      font-weight: normal;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        color: #e60012;
      }
      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 2.5%;
        margin-top: -5px;
        box-sizing: border-box;
        width: 4px;
        height: 4px;
        border: 4px solid transparent;
        border-left: 5px solid #e60012;
        -webkit-transition: 0.4s;
        -moz-transition: 0.4s;
        -o-transition: 0.4s;
        -ms-transition: 0.4s;
        transition: 0.4s;
      }
      &.on {
        color: #e60012;
      }
      &.l-space {
        letter-spacing: -1px;
      }
      &.arrow {
        &:before {
          position: absolute;
          content: "";
          top: 50%;
          left: 2.5%;
          width: 7px;
          height: 7px;
          margin-top: -6px;
          box-sizing: border-box;
          border: 0 solid transparent;
          border-left: none;
          border-bottom: 2px solid #e60012;
          border-right: 2px solid #e60012;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      &.fs-16 {
        font-size: 16px;
      }
    }
    &.on {
      a {
        &.arrow {
          &:before {
            margin-top: -2px;
            -webkit-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
    &.arrow-content {
      display: none;
      width: 100%;
      margin: 0;
      padding: 4% 4% 0;
      background-color: #f5f5f5;
      a {
        float: left;
        width: 48%;
        margin: 0 0 4%;
        padding: 0;
        &:nth-child(2n) {
          float: right;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:before {
          display: none;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 0 4px;
        }
        span {
          display: block;
          position: relative;
          padding-left: 12px;
          &:before {
            position: absolute;
            content: "";
            top: 4px;
            left: 0;
            margin-top: 0;
            box-sizing: border-box;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 5px solid #e60012;
          }
        }
      }
    }
  }
  &.border-none {
    li {
      border: none;
    }
  }
}

.list-definition {
  padding-bottom: 5px;
  dt {
    font-size: 12px;
    margin-bottom: 5px;
  }
  dd {
    font-size: 10px;
    padding-bottom: 20px;
  }
}

.tab-three-col {
  margin: 0 -2.5% 2.5%;
  border-bottom: solid 1px #e0e0e0;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0;
    a {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      min-height: 60px;
      padding: 12px 2.5% 12px 6.6%;
      background-color: #fff;
      color: #222;
      font-size: 13px;
      text-decoration: none;
      line-height: 1.4;
      &:hover {
        background-color: #f5f5f5;
      }
      &.on {
        background-color: #f5f5f5;
      }
    }
    &.on a {
      background-color: #f5f5f5;
    }
  }
}

.prof {
  margin: 0 0 3%;
  padding: 0 0 5%;
  border-bottom: solid 1px #e6e6e6;
  .img-area {
    float: left;
    width: 41%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .txt-area {
    float: left;
    width: 45%;
    padding: 19% 0 0 0;
    text-align: right;
    .position {
      display: inline-block;
      padding-bottom: 0;
      font-size: 12px;
    }
    img {
      width: 43%;
      height: auto;
    }

  }
}

.global-two-col {
  margin: 0 0 5%;
  padding: 0 2.5%;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 7% 0;
    &:nth-child(2n) {
      float: none;
    }
    h3 {
      margin: 0 0 4%;
      padding: 0 0 8px 0;
      border-bottom: dashed 1px #bfbfbf;
      font-size: 16px;
      line-height: 1.2;
      text-align: left;
      .num {
        font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", "sans-serif", "ＭＳ Ｐゴシック", "MS PGothic";
        display: block;
        float: left;
        width: 24px;
        height: 24px;
        margin: -3px 0 0 0;
        padding: 4px 0 0 0;
        background-color: #e60012;
        color: #fff;
        font-size: 14px;
        line-height: 1.2;
        text-align: center;
        font-weight: bold;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        &.space-r {
          margin-right: 5px;
        }
      }
    }
    .img-area {
      width: 100%;
      margin: 0 0 8px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .info-area {
      float: left;
      width: 100%;
      margin-bottom: 2%;
      line-height: 1.6;
      dt {
        clear: both;
        float: left;
        width: 35%;
        font-size: 12px;
        span {
          letter-spacing: 2em;
        }
        &.shikichi,
        &.kibo,
        &.youto {
          span {
            letter-spacing: 1em;
          }
        }
        &.nobeyuka {
          span {
            letter-spacing: 0.2em;
          }
        }
        &.shunkou {
          span {
            letter-spacing: 5em;
          }
        }
      }
      dd {
        float: left;
        width: 65%;
        font-size: 12px;
      }
    }
    .txt-area {
      clear: both;
      ul {
        margin: 0 0 0 0;
        padding: 0 0 0 20px;
        font-size: 12px;
        text-align: left;
        li {
          display: list-item;
          float: none;
          width: auto;
          margin: 0 0 0 0;
          list-style-type: disc;
          &:nth-child(2n) {
            float: none;
          }
        }
      }
    }
  }
}

.img-center {
  margin: 0 0 5%;
  line-height: 0;
  text-align: center;
  &.space-b {
    margin-bottom: 7%;
  }
  &.b-none {
    margin-bottom: 0;
  }
  &.b-short {
    margin-bottom: 2%;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  &.mini {
    margin-bottom: 7%;
    img {
      width: 39%;
      height: auto;
    }
  }
  &.middle {
    img {
      width: 80%;
      height: auto;
    }
  }
  &.img-red {
    margin-top: -60px;
    margin-bottom: -2%;
    margin-left: 20%;
    img {
      width: 60%;
      margin: 0 0 0 100px;
    }
  }
}

// icon
#main .icon {
  &-doc {
    display: inline-block;
    padding-left: 20px;
    position: relative;
    &::before {
      background: url(/common/img/icon_doc.png) 0 0 no-repeat;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 15px;
      margin-top: 3px;
      padding: 0;
      border: none;
    }
  }
  &-doc-blue {
    display: inline-block;
    padding-left: 20px;
    position: relative;
    &::before {
      background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
      background-size: 15px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 15px;
      height: 15px;
      margin-top: 3px;
      padding: 0;
      border: none;
      border-left: none;
    }
  }
  &-pdf {
    display: inline-block;
    padding-left: 25px;
    position: relative;
    &::before {
      background: url(/common/img/icon_pdf.png) 0 0 no-repeat;
      background-size: 20px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: 2px;
      padding: 0;
      border: none;
    }
  }
  &-serch {
    display: inline-block;
    padding-left: 24px;
    position: relative;
    &::before {
      background: url(/common/img/icon_serch.png) 0 0 no-repeat;
      background-size: 14px auto;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      margin-top: 4px;
      padding: 0;
      border: none;
    }
  }
}

.adobe-sp {
  display: block;
  width: 50%;
  margin: 0 auto;
}

.img-link {
  margin: 0 -#{$sp_contents_padding} 5%;
  line-height: 0;
  text-align: center;
  &.b-none {
    margin-bottom: 0;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  .txt-area {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    .ttl {
      color: #fff;
      font-size: 4.53vw;
      font-weight: bold;
      margin-bottom: 15px;
      &.black {
        color: $color_black;
      }
    }
    .tel {
      span {
        color: #fff;
        display: inline-block;
        font-size: 8vw;
        font-weight: bold;
        line-height: 1;
        position: relative;
        a {
          color: #fff;
        }
      }
    }
  }
  .img-area {
    overflow: hidden;
    img {
      margin: 0 -30% 0 -20%;
      width: 150%;
    }
  }
  &.txt-shadow {
    .ttl,
    .tel {
      text-shadow: 0 0 20px #000;
    }
  }
}

.img-right-wrap {
  margin-bottom: 5%;
  &.b-wide {
    margin-bottom: 5%;
  }
  &.b-short {
    margin-bottom: 5%;
  }
  p {
    float: none;
    width: 100%;
    padding: 0 0 0 0;
    margin-right: 0;
  }
  .size-adjust{
    width: 100%;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin: 0 0 4%;
  }
  .clear-size{
    max-width: 250px;
    margin: 0 auto;
  }
  &.upper-img {
    p {
      float: none;
      width: 100%;
      margin-right: 0;
      padding: 0 0 0 0;
    }
    img {
      float: none;
      margin-right: 0;
    }
  }
}

.img-left-wrap {
  margin-bottom: 5%;
  &.b-wide {
    margin-bottom: 5%;
  }
  img {
    display: block;
    float: none;
    width: 100%;
    height: auto;
    margin: 0 0 4%;
  }
  p {
    float: none;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 3%;
    background-color: #f5f5f5;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: -webkit-center;
    align-items: -moz-flexcenter;
    align-items: -ms-center;
    align-items: -o-center;
    align-items: center;
    justify-content: -webkit-center;
    justify-content: -moz-flexcenter;
    justify-content: -ms-center;
    justify-content: -o-center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .info-area {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 3%;
    background-color: #f5f5f5;
    font-size: 13px;
    line-height: 1.6;
    dt {
      clear: both;
      float: left;
      width: 40%;
      span {
        letter-spacing: 2em;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        span {
          letter-spacing: 1em;
        }
      }
      &:nth-of-type(4) {
        span {
          letter-spacing: 0.5em;
        }
      }
      &:nth-of-type(5) {
        span {
          letter-spacing: 5em;
        }
      }
    }
    dd {
      float: left;
      width: 60%;
    }
  }
}

.break-line {
  width: 14%;
  height: 0;
  margin: 0 auto 10%;
  padding: 6% 0 0;
  border-bottom: solid 1px #D6D6D6;
  &.short {
    height: 3%;
    margin: 0 auto 5%;
  }
}

.list-detail {
  margin: 0 0 5%;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 2.5%;
    background-color: #f5f5f5;
    &:nth-child(odd) {
      clear: both;
    }
    &:nth-child(2n) {
      float: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 2.5%;
    }
    a {
      display: block;
      text-decoration: none;
    }
    .img-area {
      float: left;
      width: 39%;
      height: 138px;
      overflow: hidden;
      img {
        width: auto;
        height: 100%;
      }
    }
    .txt-area {
      float: left;
      width: 61%;
      height: 138px;
      padding: 0 0 0;
      text-align: center;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: -webkit-center;
      align-items: -moz-flexcenter;
      align-items: -ms-center;
      align-items: -o-center;
      align-items: center;
      justify-content: -webkit-center;
      justify-content: -moz-flexcenter;
      justify-content: -ms-center;
      justify-content: -o-center;
      justify-content: center;
      -webkit-flex-direction: column;
      flex-direction: column;
      h3 {
        margin: 0 0 8px;
        padding: 0;
        color: #222;
        font-size: 18px;
        text-align: center;
        line-height: 1.2;
        &.check {
          color: #0069a0;
        }
        &.eco {
          color: #76a22d;
        }
        &.custom {
          color: #00498a;
        }
        &.life {
          color: #d0850c;
        }
        &.commu {
          color: #b94546;
        }
        span {
          display: block;
          margin: 0 0 0;
          padding: 0;
          color: #333;
          font-size: 13px;
          line-height: 1.7;
        }
      }
    }
  }
}

.t-flow {
  width: 100%;
  margin: 0 0 5%;
  &.b-short {
    margin-bottom: 5%;
  }
  th {
    display: block;
    width: 92%;
    margin: 0 4%;
    padding: 3% 0;
    background-color: #F5F5F5;
    font-size: 16px;
    line-height: 1.4;
    vertical-align: middle;
    &.red {
      color: #E50013;
    }
  }
  td {
    display: block;
    width: 92%;
    margin: 0 4%;
    padding: 2% 0;
    vertical-align: middle;
    &.arrow {
      margin-bottom: 5%;
      padding: 2% 0 2%;
      &+td {
        display: none;
      }
      div {
        position: relative;
        margin: 0 auto;
        width: 2px;
        height: 15px;
        background-color: #C61D21;
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: -5px;
          margin: auto;
          width: 10px;
          height: 0;
          border: 6px solid transparent;
          border-top: 10px solid #C61D21;
        }
      }
    }
  }
}

.t-flow.mod_redevelopment {
  width: 100%;
  margin: 0 0 5%;
  transform: translate(0,0);
  &.b-short {
    margin-bottom: 5%;
  }
  th {
    display: block;
    width: 100%;
    margin: 0;
    padding: 3% 0;
    font-size: 16px;
    line-height: 1.4;
    vertical-align: middle;
    em {
      padding: 0 10px;
    }
    span {
      padding-left: 5px;
    }
    &.red {
      color: #E50013;
    }
  }
  td {
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px;
    vertical-align: middle;
    border-top: 0px !important;
    transform: translate(0,0);
    &.arrow {
      margin-bottom: 5%;
      padding: 2% 0 2%;
      &+td {
        display: none;
      }
      div {
        position: relative;
        margin: 0 auto;
        width: 2px;
        height: 15px;
        background-color: #C61D21;
        &:after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: -5px;
          margin: auto;
          width: 10px;
          height: 0;
          border: 6px solid transparent;
          border-top: 10px solid #C61D21;
        }
      }
    }//&.arrow
  }// td
  .mod_col1 {
    margin: 0 auto;
    width: 100% !important;
    li {
      width: 100% !important;
      font-size: 18px;
    }
  }
  .mod_col2 {
    li {
      width: 100% !important;
      font-size: 14px;
    }
  }
  tr {
    display: block;
    margin: 0 0 30px;
  }// tr
}//.t-flow.mod_redevelopment

.t-point {
  width: 100%;
  margin: 0 0 10%;
  th {
    display: block;
    width: 100%;
    padding: 3% 0;
    background-color: #FFEFE6;
    border-bottom: none;
    font-size: 16px;
    line-height: 1.4;
    vertical-align: middle;
    text-align: center;
  }
  td {
    display: block;
    width: 100%;
    padding: 2% 0;
    border-bottom: solid 5px #fff;
    vertical-align: middle;
  }
}

.disc {
  padding-left: 20px;
  &.space-b {
    margin-bottom: 7%;
  }
  li {
    list-style-position: outside;
    list-style-type: disc;
  }
  &.space {
    li {
      margin-bottom: 5%;
    }
  }
}

.no-disc {
  li {
    list-style-type: none;
  }
  &.space-l {
    padding-left: 20px;
    li {
      padding-left: 20px;
      text-indent: -20px;
    }
  }
  &.space {
    li {
      margin-bottom: 5%;
    }
  }
}

.disc-float {
  padding-left: 20px;
  li {
    float: none;
    width: 100%;
    margin-right: 0;
    list-style-position: outside;
    list-style-type: disc;
    &:nth-child(2n) {
      width: 100%;
      margin-right: 0;
    }
    &:nth-child(2n+1) {
      clear: both;
    }
  }
}

.accordion {
  margin: 0 0 5%;
  li {
    margin: 0 0 3%;
    padding: 0 15px 0;
    background-color: #F5F5F5;
    border: silid 1px #EBEBEB;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    .question {
      position: relative;
      margin: 0 0 0;
      padding: 15px 0 15px 30px;
      font-size: 16px;
      line-height: 1.4;
      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        width: 19px;
        height: 19px;
        margin-top: -10px;
        background-color: #fff;
        border: 1px solid #C81C1E;
        text-align: center;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
      }
      &:after {
        content: "+";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        top: 48%;
        left: 0;
        color: #C81C1E;
        text-align: center;
      }
      &.on {
        &:before {
          background-color: #C81C1E;
        }
        &:after {
          content: "−";
          top: 48%;
          left: 0;
          color: #fff;
        }
      }
    }
    .answer {
      display: none;
      padding: 15px 0 15px;
      border-top: dashed 1px #d0d0d0;
    }
    &:nth-child(1) {
      .answer {
        display: block;
      }
    }
  }
}

.img-two-col {
  margin: 0 0 5%;
  padding: 0 0;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 2.5%;
    &:nth-child(2n) {
      float: right;
    }
    .img-area {
      img {
        width: 100%;
        height: auto;
      }
    }
    .txt-area {
      h3 {
        margin: 0;
        padding: 2% 0 2%;
        color: #222;
        font-size: 13px;
        text-align: center;
        line-height: 1.4;
      }
      p {
        margin: 0 0 2%;
        text-align: left;
      }
    }
  }
  &.remain-two-sp {
    margin: 0 0 5%;
    padding: 0 0;
    li {
      display: block;
      float: left;
      width: 48%;
      margin: 0 0 0 0;
      &:nth-child(2n) {
        float: right;
      }
      .img-area {
        margin-bottom: 2%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .txt-area {
        h3 {
          margin: 0;
          padding: 2% 0 2%;
          color: #222;
          font-size: 13px;
          text-align: center;
          line-height: 1.4;
        }
        p {
          margin: 0 0 2%;
          text-align: left;
          font-size: 12px;
          word-break: break-all;
        }
      }
    }
  }
  &-autoheight {
    display: block;
    h3 {
      font-size: 15px;
      padding-left: 0;
    }
    li {
      width: 100%;
      padding-bottom: 15px;
    }
  }
  &.space-lr02 {
    padding: 0;
  }
  &.narrow {
    margin: 0 0 25px;
    width: auto;
    li {
      margin-bottom: 5px;
    }
    .txt-area {
      margin-top: 2%;
    }
  }
}

.img-three-col {
  margin: 0 0 5%;
  padding: 0 0;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 2.5% 0;
    &:nth-child(3n+1) {
      clear: both;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 2.5%;
    }
    &:nth-last-child(3) {
      margin-bottom: 2.5%;
    }
    .img-area {
      img {
        width: 100%;
        height: auto;
      }
    }
    .txt-area {
      text-align: center;
      h3 {
        margin: 0;
        padding: 3% 0 1%;
        color: #222;
        font-size: 15px;
        text-align: center;
        line-height: 1.4;
      }
      p {
        font-size: 14px;
        display: inline-block;
        text-align: left;
      }
    }
  }
}

.gray-box {
  margin: 0 auto 5%;
  padding: 5% 0 5%;
  background-color: #ebebeb;
  text-align: center;
  h3 {
    margin: 0 0 3%;
    padding: 0;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
  }
  &.space-tb {
    padding: 5% 0 10%;
    h3 {
      font-size: 24px;
    }
  }
  &.narrow {
    width: 100%;
  }
  &.w-full {
    width: auto;
    margin: 0 -2.5% 5%;
    padding: 7% 0 7%;
  }
}

.gray-box02 {
  margin: 0 auto 40px;
  padding-top: 28px;
  padding-bottom: 30px;
  background-color: #f5f5f5;
  h3 {
    padding-left: 0;
  }
  &.space-t {
    padding: 10px 0 0;
  }
  &.space-tb {
    padding: 30px 0 5px;
  }
  &.space-tb02 {
    padding-top: 20px;
    padding-bottom: 0;
  }
  &.narrow {
    padding: 5px;
    width: 100%;
    .white-box {
      padding: 30px 20px;
    }
    .img-area {
      display: block;
      margin-bottom: 25px;
      text-align: center;
    }
  }
  &.little-large {
    width: auto;
    margin-bottom: 7%;
  }
  .point-three-col {
    padding: 0 5px 20px;
  }
  .box-line {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
    padding-bottom: 10px;
    &-dotted {
      border-bottom: 1px dotted #e1e1e1;
      margin-bottom: 20px;
    }
  }
  .img-area {
    padding: 0 5px;
    text-align: left;
    .img-caption {
      margin-bottom: 15px;
    }
  }
}

.gray-box03 {
  margin: 0 auto 40px;
  background-color: #eee;
  &,
  &.medium {
    padding: 5% 10px 10px;
  }
  &.narrow,
  &.medium {
    width: 100%;
  }
  &.medium {
    .img-caption {
      margin-bottom: 5px;
    }
  }
  &.space-tblr {
    margin: 0 auto 70px;
    padding: 20px $sp_contents_padding 0;
  }
  h3 {
    font-size: 15px;
  }
  .h-center {
    text-align: center;
  }
  .white-box {
    padding: 30px 5% 20px;
    li {
      border-bottom: 2px solid #e0e0e0;
      margin-bottom: 30px;
      padding-bottom: 20px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -33px;
        margin: 0 auto;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        border: 15px solid transparent;
        border-top: 18px solid #e0e0e0;
      }
      &:last-child {
        border-bottom: 0 none;
        margin-bottom: 0;
        padding-bottom: 0;
        &::after {
          content: none;
        }
      }
    }
    dl {
      dt {
        font-size: 16px;
        margin-bottom: 7px;
      }
    }
    .txt-area-gray {
      background-color: #f5f5f5;
      display: inline-block;
      font-size: 15px;
      margin: 0 auto 20px;
      padding: 10px 20px;
      width: auto;
    }
  }
  .img-area {
    margin: 0 auto 10px;
    width: 30.15%;
    &-two-col {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        margin-right: $sp_contents_padding;
        width: 47.03%;
      }
    }
  }
  .txt-area {
    display: inline-block;
    text-align: left;
  }
  .img-caption {
    font-size: 15px;
    margin: 15px 0 5px;
    text-align: center;
  }
  .inner_col2 {
    display: block;
    .col_main {
      margin-bottom: 15px;
      width: auto;
    }
    .col_sub {
      padding-bottom: 10px;
      width: auto;
      text-align: center;
    }
    p {
      margin: 0 !important;
    }
  }
}

.white-box {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  .txt-area {
    margin: 20px 0;
    text-align: left;
  }
}
a.white-box {
  display: block;
  &,
  &:link,
  &:visited {
    color: $color_black;
  }
  &:hover {
    color: $link_hover;
    transition: color $hover_animate;
  }
}

.border-box {
  margin: 0 auto 5%;
  padding: 5% 0 5%;
  border: solid 1px #ebebeb;
  text-align: center;
  h3 {
    margin: 0 0 3%;
    padding: 0;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
  }
  &.narrow {
    width: 100%;
  }
  &.b-short {
    margin-bottom: 2%;
  }
  &.none {
    margin-bottom: 0;
  }
  .sup {
    font-size: 12px;
  }
}

.logo-three-col {
  margin: 0 0 5%;
  padding: 0 0;
  li {
    display: block;
    float: none;
    width: 100%;
    margin: 0 0 2.5% 0;
    padding: 0 10px 10px;
    background-color: #f5f5f5;
    text-align: center;
    &:nth-child(3n+1) {
      clear: both;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:nth-last-child(2) {
      margin-bottom: 2.5%;
    }
    &:nth-last-child(3) {
      margin-bottom: 2.5%;
    }
    h3 {
      margin: 0;
      padding: 15px 0;
      color: #222;
      font-size: 15px;
      text-align: center;
      line-height: 1.4;
    }
    .h3-adjust{
      padding: 15px 0;
      line-height:  1.4;
    }
    .h3-subtxt{
      font-size: 12px;
      padding: 0;
    }
    .logo-inner {
      padding: 5% 7% 5%;
      background-color: #fff;
    }
    .img-area {
      height: auto;
      margin: 0 0 0;
      padding: 5% 0;
      line-height: 0;
      img {
        width: 55%;
        height: auto;
        &.middle {
          width: 80%;
        }
        &.big {
          width: 100%;
        }
      }
    }
    .txt-area {
      height: auto;
      margin: 0 0 5%;
      font-size: 14px;
      text-align: left;
    }
  }
}

.sns-list {
  margin: 0 0 2%;
  text-align: center;
  &.type02 {
    li {
      margin: 0;
    }
    > :first-child {
      margin: 0 0 5%;
    }
  }
  li {
    display: inline-block;
    margin: 0 0 5%;
    padding: 0 8px 0;
    img {
      display: block;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
    span {
      position: relative;
      padding-left: 15px;
      color: $color_black;
      font-size: 14px;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        border: 4px solid transparent;
        border-left: 5px solid #e60012;
      }
    }
  }
}

.dl00 {
  dt {
    margin: 5% 0 2%;
    font-size: 14px;
    font-weight: bold;
    text-indent: -2.0em;
    padding-left: 2.0em;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
    .strong {
      font-weight: bold;
    }
  }
}

.dl01 {
  dt {
    margin-bottom: 2%;
    font-size: 16px;
    font-weight: bold;
    text-indent: -1.7em;
    padding-left: 1.7em;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    margin-bottom: 7%;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
  }
}

.dl02 {
  dt {
    margin: 5% 0 2% 0;
    font-size: 16px;
    font-weight: bold;
    text-indent: -1.7em;
    padding-left: 1.7em;
  }
  dd {
    margin-bottom: 5%;
    p {
      margin-bottom: 0;
    }
    &.last {
      margin-bottom: 0;
    }
  }
  .ddList {
    list-style-type: disc;
    margin-left: 26px;
    margin-bottom: 10px;
    .text {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}

.dl03 {
  dt {
    float: left;
    clear: both;
    padding-right:5px;
    &.strong {
      font-weight: bold;
    }
  }
  dd {
    overflow: hidden;
    margin-left: 1.5em;
    margin-bottom: 3%;
    p {
      margin-bottom: 0;
      &.strong {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .strong {
      font-size: 16px;
      font-weight: bold;
    }
    .left-area {
      clear: both;
      float: none;
      width: 100%;
      margin-bottom: 2%;
    }
    .middle-area {
      display: none;
    }
    .right-area {
      float: none;
      width: 100%;
      margin: 0 0 5%;
      padding: 10px;
      border: dashed 1px #999;
    }
    .txt-large {
      font-size: 16px;
    }
  }
}

.small-arrow {
  position: relative;
  display: inline-block;
  padding-left: 15px;
  font-size: 14px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    border: 4px solid transparent;
    border-left: 5px solid #e60012;
  }
}

.sitemap-wrap {
  width: 100%;
  margin: 0 auto;
  .dl_sitemap01 {
    margin-top: 0;
    width: 100%;
    &.left {
      float: none;
    }
    &.right {
      float: none;
    }
    dt {
      margin: 5% 0 2%;
      border-bottom: solid 1px #ebebeb;
      color: $color_black;
      font-size: 16px;
      font-weight: bold;
      &:first-of-type {
        margin-top: 0;
      }
      a {
        position: relative;
        display: inline-block;
        width: 100%;
        color: $color_black;
        &:hover {
          color: #c57800;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 5px;
          bottom: 0;
          margin: auto;
          vertical-align: middle;
          width: 4px;
          height: 4px;
          border: 4px solid transparent;
          border-left: 5px solid #e60012;
        }
      }
    }
    dd {
      margin: 0 0 2% 14px;
      a {
        position: relative;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        padding-left: 14px;
        background: url(/common/img/ico_arrowB03.gif) no-repeat left 0.5em;
        &:hover {
          background-image: url(/common/img/ico_arrowB02.gif);
        }
      }
      &.dd_2nd {
        margin-left: 31px;
        a {
          padding-left: 9px;
          background-image: url(/common/img/ico_arrowR01.gif);
          &:hover {
            background-image: url(/common/img/ico_arrowR02.gif);
          }
        }
      }
      &.dd_3nd {
        margin-left: 50px;
        a {
          padding-left: 9px;
          background-image: url(/common/img/ico_arrowR01.gif);
          &:hover {
            background-image: url(/common/img/ico_arrowR02.gif);
          }
        }
      }
      &.outer-link {
        a {
          &:after {
            background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
            background-size: 15px auto;
            content: "";
            position: absolute;
            top: 0;
            right: -22px;
            width: 15px;
            height: 15px;
            margin-top: 4px;
            padding: 0;
            border: none;
          }
        }
      }
      &.nonJump {
      padding-left: 15px;
      background: url(/common/img/ico_arrowB03.gif) no-repeat left 0.5em;
      }
    }
  }
}

// table
.t-standard {
  border: 1px solid #e0e0e0;
  border-left: 0 none;
  border-right: 0 none;
  margin-bottom: 40px;
  table-layout: fixed;
  word-break: break-all;
  &.narrow {
    width: 100%;
  }
  &.head-l {
    th {
      width: auto;
    }
  }
  &.head-l02 {
    th {
      width: 30%;
    }
  }
  &.head-l03 {
    th {
      width: 35%;
    }
  }
  &.head-left {
    th {
      text-align: left;
    }
  }
  &.head-nowrap {
    th {
      white-space: normal;
    }
  }
  &.tb-guidance {
    table-layout: fixed;
    border-bottom: none;
    th,
    td {
      display: block;
      width: auto;
    }
    td:nth-of-type(1) {
      width: auto;
      padding: 10px 0 0;
      border-bottom: none;
      text-align: center;
    }
    td:nth-of-type(2) {
      width: auto;
      padding: 0;
      border-bottom: none;
      vertical-align: top;
      text-align: center;
    }
    td:nth-of-type(3) {
      padding: 0 0 10px;
      text-align: center;
    }
  }
  th,
  td {
    border-bottom: 1px solid #e0e0e0;
    vertical-align: middle;
  }
  th {
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    font-size: 13px;
    padding: 10px 5px;
    text-align: center;
  }
  td {
    font-size: 12px;
    padding: 10px 0 10px 10px;
    text-align: left;
  }
  .b-none {
    th,
    td {
      border-bottom:none;
    }
  }
  .sup {
    font-size: 12px;
    font-weight: normal;
  }
  .td-btn {
    display: block;
    float: none;
    margin: 0 auto;
    width: 80%;
    height: auto;
    &.td-style01 {
      max-width: 180px;
      margin: 10px auto;
    }
  }
}

// ↓guidance.hetml使用
.tl-auto { // sp時table-layout強制解除
  table-layout: auto!important;
}
.guidanceTopText {
  width: 100%;
  .lSpace01{
    margin-left: 0;
  }
}

.movie-area {
  margin-bottom: 30px;
  text-align: center;
  .movie-inr {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .txt-area {
    margin-top: 10px;
    &.movie_caption {
      margin: 0 auto;
      width: auto;
      small {
        display: block;
        font-weight: normal;
      }
    }
  }
}

.movie_profile {
  display: block;
  margin: 0 auto 30px;
  width: auto;
  figure {
    width: auto;
    text-align: center;
    img {
      width: auto;
    }
  }
  p {
    width: auto;
    font-size: 12px;
    em {
      text-align: center;
      font-size: 14px;
    }
    small {
      font-weight: normal;
      font-size: 11px;
    }
  }
}

.graphic-four-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 3%;
  width: 100%;
  &::before,
  &::after {
    content: none;
  }
  li {
    margin: 0 $sp_contents_padding 5%;
    width: 45%;
  }
  dt {
    background-color: $color_black;
    color: #fff;
    padding: 5px 0;
    text-align: center;
  }
  dd {
    border: 0 none;
    min-height: 0;
    img {
      border: 1px solid $color_black;
      border-top: 0 none;
    }
  }
}

// modal
#cboxOverlay {
  background-color: #000;
}
#cboxContent,
#cboxLoadingGraphic,
#cboxLoadedContent {
  background-color: #fff;
}
#cboxContent {
  margin-top: 0;
}
#cboxClose {
  background: none;
  position: absolute;
  top: 25px;
  right: 25px;
  text-indent: -9999px;
  overflow: hidden;
  width: 27px;
  height: 20px;
  &::before,
  &::after {
    content: "";
    display: block;
    background-color: #120603;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 9px;
    left: 0;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(135deg);
  }
  &:hover {
    opacity: $hover_opacity;
    transition: opacity $hover_animate;
  }
}

.modal {
  display: none;
  &-contents {
    padding: 10% 4.47% 5px;
    width: 100%;
    .date {
      margin-bottom: 2px;
      text-align: center;
    }
    .img-area {
      margin: 0 auto 10px;
      width: 67%;
      img {
        border: 1px solid #313131;
      }
    }
    .txt-area {
      .copy {
        font-size: 17px;
        margin-top: 30px;
        text-align: center;
      }
      small {
        display: block;
        font-size: 10px;
        margin-top: 27px;
        text-align: center;
      }
    }
  }
  &-two-col {
    display: block;
  }
}

.t-point2 {
  width: 100%;
  height: auto;
  margin: 0 0 35px;
  &.b-short {
    margin-bottom: 2%;
  }
  .space-tb {
    margin: 0;
  }
  tr:first-child {
    th,
    td {
      border-top: 0 none;
    }
  }
  th {
    display: block;
    border-bottom: 0 none;
    width: auto;
    height: auto;
    .ttl-inner {
      display: block;
      padding: 3% 0;
      background-color: #F5F5F5;
      font-size: 16px;
      line-height: 1.4;
      vertical-align: middle;
      text-align: center;
      height: auto;
      margin: 0;
    }
  }
  td {
    display: block;
    padding: 2%;
    border-bottom: 0 none;
    vertical-align: middle;
  }
}

.t-cell {
  width: 100%;
}

.point-two-col {
  display: block;
  li {
    text-indent: -1em;
    padding-left: 1em;
    &:nth-child(2n+1),
    &:nth-child(2n) {
      width: auto;
    }
  }
}

.point-three-col {
  display: block;
  text-align: left;
  &::after {
    content: none;
  }
  li {
    width: auto;
    text-indent: -1em;
    padding-left: 1em;
    margin-bottom: 3px;
  }
}

// newsereleace
.newsreleace {
  &-tab-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.5%;
    width: auto;
    padding-bottom: 0;
    &::before,
    &::after {
      display: block;
      content: "";
      width: 23%;
    }

    &::before {
      order:1;
    }

    li {
      padding: 0;
      margin-bottom: 10px;
      width: 23%;
      a {
        border: 1px solid #e0e0e0;
        color: $color_black;
        display: block;
        font-size: 15px;
        padding: 10px 0;
        text-align: center;
        &:hover {
          color: $link_hover;
          transition: color $hover_animate;
        }
      }
      &.on {
        a {
          background-color: $color_red;
          border-color: $color_red;
          color: #fff;
        }
      }
    }
  }

  &-list {
    margin-bottom: 25px;
    li {
      &.new {
        dt {
          position: relative;
          &::before {
            content: none;
          }
          &::after {
            color: $color_red;
            content: "NEW";
            display: inline-block;
            margin-left: 7px;
          }
        }
      }
    }
    dl {
      display: block;
      margin: 20px #{- $sp_contents_padding} 0;
      padding: 0 #{$sp_contents_padding * 2} 20px;
      border-bottom: 1px dotted #e7e7e7;
      dt {
        font-size: 10px;
        color: $color_black;
        margin-right: 20px;
      }

      dd {
        word-break: break-all;

        a {
          color: $color_black;
        }
      }
    }
  }
}

.img-box-l,
.img-box-r {
  display: block;
  min-height: 1%;
}
.img-box-l .flt-img {
  display: block;
  float: none;
  margin-right: 0;
}
.img-box-r .flt-img {
  display: block;
  float: none;
  margin-left: 0;
}

.img-box-r02 {
  display: block;
  min-height: 1%;
  &::after {
    clear: both;
    content:".";
    display: block;
    height: 0;
    visibility: hidden;
  }
  .flt-text {
    float: left;
  }
  .flt-img {
    display: inline;
    float: right;
    margin-left: 20px;
  }
}

// history
.history {
  padding: 0;
  dt {
    clear: both;
    float: left;
    width: 6em;
    font-weight: bold;
  }
  dd {
    margin-bottom: 10px;
    padding: 0 0 10px 6.5em;
  }
}

// map modal
#TB_overlay,
#TB_window {
  z-index: 10001;
}
#TB_window {
  width: 100% !important;
  left: 0;
  margin-left: 0;
}
#TB_title {
  height: auto;
  &::after {
    content:" ";
    display:block;
    clear:both;
  }
}
#TB_ajaxWindowTitle {
  float: none;
}
#TB_closeAjaxWindow {
  clear: both;
  margin-bottom: 10px;
  padding: 0;
}
#TB_iframeContent {
  width: 100% !important;
}

.text-two-col-vertical {
  & > * {
    float: none;
    margin-right: 0;
  }
}

// news-info
.news-info {
  padding: 0;
  dt {
    clear: both;
    float: none;
    margin-bottom: 10px;
    width: auto;
  }
  dd {
    margin-bottom: 20px;
    padding: 0;
  }
  .info-title {
    border:solid 1px #c9c9c9;
    line-height: 1.78;
    padding:5px;
    text-align: center;
  }
}

.border-double-box {
  margin-bottom: 5%;
  padding: 4px;
  text-align: center;
  &,
  &-inner {
    border: 1px solid #908b8a;
  }
  &.narrow {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &-ttl {
    border-bottom: 1px solid #908b8a;
    padding: 10px;
    margin-bottom: 10px;
  }
}


/* form */
.form-wrap {
  margin-bottom: 0;
  padding: 25px 2.5%;
  width: auto;

  #mailformpro {
    dt {
      font-size: 18px;
    }

    dd {
      margin-bottom: 20px;
    }

    label {}

    input,
    select {
      padding: 10px;
    }

    .select-wrap {
      width: 32%;

      select {
        padding-right: 25px;
      }
    }

    .postal-wrap {
      justify-content: flex-start;
      flex-wrap: wrap;

      input {
        width: 35%;

        &#postal-code {
          width: 30%;
        }
      }

      .text-wrap {
        text-align: center;
        width: 8%;

        &:first-child {
          width: 10%;
        }
      }

      .select-wrap {
        margin: 10px 0 0;
        width: 100%;
      }
    }

    .text-wrap {
      padding: 0 2.5%;
    }

    .file-wrap {
      label {
        display: block;
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .btn-wrap {
      text-align: center;
    }

    .btn {
      &:hover {
        background: #e60012;
      }
    }

    // error
    div.mfp_err {
      background: none;
      margin: 5px 0 0;
      color: #e60012;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      padding: 0;
    }
  }
}

// 確認画面
div#mfp_phase_confirm h4 {
  font-size: 20px;
}

table#mfp_confirm_table {
  tr {
    th,
    td {
      font-size: 14px;
      padding: 10px 5px;
    }

    th {
      width: 140px;
    }
  }
}

div.mfp_buttons {
  display: block;
  padding: 30px 25px 20px;

  .mfp_element_button {
    display: block;
    width: 100%;

    span {
      display: inline-block;
    }

    &#mfp_button_send {
      margin-bottom: 20px;

      &:hover {
        background: #e60012;
      }
    }

    &#mfp_button_cancel {
      &:hover {
        background: #fff;
      }
    }
  }
}

// 完了画面
.form-completion-wrap {
  margin-bottom: 65px;
}


/* ============================================
	English site
============================================ */

#header_en {
	position: relative;
	overflow: hidden;
	border-top: 3px solid #e70a1b;
	width: auto;
  min-width: auto;
	text-align: right;
	zoom: 1;
  .header-en-inner {
    width: 95%;
    margin: 0 auto;
  }
  h1 {
    float: left;
    width: 60%;
    margin: 5% 0 5% 0;
    padding: 0;
    line-height: 0;
    text-align: left;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .headUtility {
    position: relative;
    overflow: hidden;
    float: right;
    margin-bottom: 0;
    padding: 4% 0 0;
    width: 31%;
    zoom: 1;
    text-align: left;
    .utilityNavi {
      position: relative;
      clear: both;
      overflow: hidden;
      zoom: 1;
      height: auto;
      li {
        &.uNav06 {
          margin-right: 0;
          width: 100%;
        }
        a {
          position: relative;
          display: block;
          padding: 2.5% 0 2.5% 15px;
          font-size: 12px;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            vertical-align: middle;
            width: 4px;
            height: 4px;
            border: 4px solid transparent;
            border-left: 5px solid #e60012;
          }
        }
      }
    }
  }
}

#contents_en {
	width: 100%;
  margin: 0 auto;
	padding: 0 0 7%;
  .main_english {
    line-height: 1.75;
    font-size: 14px;
    clear:both;
  }
}

div #box_main_en {
  width: auto;
  height: auto;
  margin: 0 auto 7%;
  padding: 7% 0 0 0;
  #box_eng_left {
    float: none;
    width: auto;
    height: auto;
    margin-bottom: 7%;
    padding: 0px 0 0 0;
  }
  #box_eng_right {
    float: none;
    width: auto;
    height: auto;
    padding: 0px 0 0 0;
  }
  .strong {
    font-size: 14px;
    font-weight: bold;
  }
  .left {
    float: left;
    width: 40%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .pdf-icon {
    padding: 30% 0 0;
    a {
      font-size: 12px;
    }
    img {
      width: 10%;
      height: auto;
      padding-right: 5px;
      vertical-align: text-bottom;
    }
  }
}

.buttons-en {
  text-align: center;
  span {
    display: block;
    width: 70%;
    margin: 0 auto 2%;
    &:first-of-type {
      padding-right: 0;
    }
    &:nth-of-type(3) {
      padding-left: 0;
    }
  }
}

#footWrap_english {
	clear: both;
	position: relative;
	width: 100%;
	height: auto;
}

#footer_english {
  position: relative;
  background: url(/common/img/footer/foot_bg01.gif) repeat-x left top;
  background-size: auto 100%;
  min-width: auto;
  margin: 0 auto;
  padding: 0;
  height: auto;
  text-align: left;
  .footBottom {
    clear: both;
    position: relative;
    width: 95%;
    height: auto;
    margin: 0 auto;
    padding: 3% 0 3%;
    overflow: hidden;
    line-height: 1.4;
    font-size: 10px;
    text-align: center;
  }
  .footLogo {
    float: none;
    margin: 0 auto 1%;
    text-align: center;
    a {
      font-family: Arial, Helvetica, Verdana, sans-serif;
      color: $color_black;
      font-size: 14px;
      font-weight: bold;
    }
  }
}


/* ============================================
	クレド credo
============================================ */
#credo {
  #header {
    padding: 5px 0 20px;
    .sp {
      .logo {
        width: 85px;
        margin: 0 0 0 15px;
        line-height: 0;
      }
    }
  }
  .com-name {
    display: block;
    width: 85%;
    margin: 0 auto;
  }
}


/* ============================================
	ブランド広告 20241029追加
============================================ */
.BrandAnchor{
  &Box {
    display: block;
    margin-bottom: 2%;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
  
  }
  &Item {
    a {
      display: block;
      position: relative;
      padding: 3.24%;
      padding-left: 11.28%;  
      font-size: 14px;
      color: #120603;
      &::before {
        content: "";
        position: absolute;
        top: 30%;
        left: 3.6%;
        width: 12px;
        height: 12px;
        border-right: 2px solid #e60012;
        border-bottom: 2px solid #e60012;
        transform: rotate(45deg);
      }
      &:hover {
        color: #e60012;
      }
    }
  }
}
.sponsor {
  &Box {
    display: block;
    width: 100%;
    margin: 0 auto 8.5%;
  }
  &List {
    width: 100%;
    margin-bottom: 8.5%;
    img {
      display: block;
      width: 100%;
      margin-bottom: 3.8%;
    }
    .tvTitle {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 1.48%;
    }
    .sponsorship {
      font-size: 12px;
      margin-bottom: 0;
    }
    .sponsorLink {
      display: inline-block;
      position: relative;
      margin-top: 1.48%;

      &:after {
        background: url(/common/img/icon_doc_blue.png) 0 0 no-repeat;
        background-size: 15px auto;
        content: "";
        position: absolute;
        top: 0;
        right: -22px;
        width: 15px;
        height: 15px;
        margin-top: 4px;
        padding: 0;
        border: none;
      }
    }
  }
}
/* ============================================
	ページバナー 20250120追加
============================================ */
.bnr_content{
  width: 90%;
  max-width:440px;
  margin: 0 auto 10%;
  box-sizing: border-box;
  img{
    width: 100%;
  }
}